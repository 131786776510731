import React, {useEffect} from "react";
import useStyles from "./style";
import Header from "../Header/header";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import firstImageOfCrid from '../../Img/int_1-min.webp'
import secondImageOfCrid from '../../Img/int_2-min.webp'
import logo from '../../Img/logo-min.webp'
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Footer from "../Footer/footer";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import Slide from '@material-ui/core/Slide';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useTheme} from "@material-ui/core";
import AcceptCGU from "../AcceptCGU/acceptCGU";
import clsx from "clsx";
import {useHistory} from "react-router";
import {ContextAuth} from "../../Services/useAuth";


export default function Home(props) {


    const auth = ContextAuth();

    if(!auth.state.isLoading) {

    }
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const refFirstSectionFirstTextOurCrib = React.useRef();
    const [hookFirstSectionFirstTextOurCrib, setHookFirstSectionFirstTextOurCrib] = React.useState(false);

    const refFirstSectionSecondTextOurCrib = React.useRef();
    const [hookFirstSectionSecondTextOurCrib, setHookFirstSectionSecondTextOurCrib] = React.useState(false);

    const refFirstSectionThirdTextOurCrib = React.useRef();
    const [hookFirstSectionThirdTextOurCrib, setHookFirstSectionThirdTextOurCrib] = React.useState(false);

    const refImplication = React.useRef();
    const [hooImplication, setHookImplication] = React.useState(false);

    const refSecondSectionFirstTextOurCrib = React.useRef();
    const [hookSecondSectionFirstTextOurCrib, setHookSecondSectionFirstTextOurCrib] = React.useState(false);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });


    React.useEffect(() => {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                });
            }

            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        },[]
    );


    function animateThing() {
        let isInViewport = function (elem) {
            let bounding = elem.getBoundingClientRect();
            let myElementHeight = elem.offsetHeight - 200;
            return (
                bounding.top >= -myElementHeight
                && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + myElementHeight
            );
        };


        if (isInViewport(refImplication.current)) {
            setHookImplication(true)
        } else {
            setHookImplication(false)
        }

        if (isInViewport(refFirstSectionFirstTextOurCrib.current)) {
            setHookFirstSectionFirstTextOurCrib(true)
        } else {
            setHookFirstSectionFirstTextOurCrib(false)
        }
        if (isInViewport(refFirstSectionSecondTextOurCrib.current)) {
            setHookFirstSectionSecondTextOurCrib(true)
        } else {
            setHookFirstSectionSecondTextOurCrib(false)
        }
        if (isInViewport(refFirstSectionThirdTextOurCrib.current)) {
            setHookFirstSectionThirdTextOurCrib(true)
        } else {
            setHookFirstSectionThirdTextOurCrib(false)
        }

        if (isInViewport(refSecondSectionFirstTextOurCrib.current)) {
            setHookSecondSectionFirstTextOurCrib(true)
        } else {
            setHookSecondSectionFirstTextOurCrib(false)
        }
    }

    useEffect(() => {
        if(!auth.state.isLoading){
            animateThing();
        }
    }, [auth.state.isLoading])

    useScrollPosition(({prevPos, currPos}) => {
        if(!auth.state.isLoading)
            animateThing();
    });


    return (
        <div>
            {
                auth.state.isLoading ? "" :
                    <div className={classes.root}>
                        <AcceptCGU/>
                        <Header width={dimensions.width} auth={auth}/>
                        {
                            dimensions.width >= theme.breakpoints.values.md ?
                                <div>

                                    <div className={classes.containerImgLogo}>

                                    </div>
                                </div>
                                :
                                <div>
                                    <div className={classes.containerImgLogoPhone}>

                                    </div>
                                </div>
                        }
                        <div className={classes.implication} ref={refImplication}>


                            <Container>
                                <Paper className={classes.paperImplication}>
                                    <Typography variant="h4" component="h3" className={classes.titleImplication}>
                                        Notre implication
                                    </Typography>
                                    <hr className={classes.hrUnderImplication}/>
                                    <Grid container justify="center">
                                        <Grid item xs={12} md={3}>
                                            <Grid container justify="center" alignItems="center"
                                                  className={clsx(classes.marginTop, classes.containerOfOneImplication)}>
                                                <Grid item xs={12}>
                                                    <Slide timeout={theme.speed.animation} in={hooImplication}
                                                           direction={dimensions.width > theme.breakpoints.values.md ? "down" : "left"}>
                                                        <HomeIcon className={classes.iconImplication}/>
                                                    </Slide>

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Slide timeout={theme.speed.animation} in={hooImplication}
                                                           direction={dimensions.width > theme.breakpoints.values.md ? "right" : "left"}>
                                                        <Typography variant="h5" component="h3"
                                                                    className={classes.textOfOneImplication}>
                                                            Un endroit conçu pour vos enfants
                                                        </Typography>
                                                    </Slide>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Grid container className={classes.containerOfOneImplication}>
                                                <Grid item xs={12}>
                                                    <Slide timeout={theme.speed.animation} in={hooImplication}
                                                           direction={dimensions.width > theme.breakpoints.values.md ? "down" : "right"}>
                                                        <GroupIcon className={classes.iconImplication}/>
                                                    </Slide>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Slide timeout={theme.speed.animation} in={hooImplication}
                                                           direction={dimensions.width > theme.breakpoints.values.md ? "up" : "right"}>
                                                        <Typography variant="h5" component="h3"
                                                                    className={classes.textOfOneImplication}>
                                                            Une équipe de passionnées
                                                        </Typography>
                                                    </Slide>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Grid container className={classes.containerOfOneImplication}>
                                                <Grid item xs={12}>
                                                    <Slide timeout={theme.speed.animation} in={hooImplication}
                                                           direction={dimensions.width > theme.breakpoints.values.md ? "down" : "left"}>
                                                        <LockIcon className={classes.iconImplication}/>
                                                    </Slide>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Slide timeout={theme.speed.animation} in={hooImplication}
                                                           direction={"left"}>
                                                        <Typography variant="h5" component="h3"
                                                                    className={classes.textOfOneImplication}>
                                                            La confiance notre priorité
                                                        </Typography>
                                                    </Slide>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Container>

                        </div>
                        <div className={classes.ourCrib}>
                            <div className={classes.containerWave}>

                                <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%"
                                     viewBox="0 0 1440 320"
                                     preserveAspectRatio="none" className={classes.wave}>
                                    <path
                                        d="M0,160L80,144C160,128,320,96,480,101.3C640,107,800,149,960,149.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z">
                                    </path>
                                </svg>
                            </div>
                            <div className={classes.containerDevelopment}>
                                <Container>
                                    <Typography variant="h3" component="h3" className={classes.titleOurCrib}>
                                        Notre crèche
                                    </Typography>
                                    <hr className={classes.hrTitle}/>
                                    <Grid container spacing={2} justify="center" alignItems="flex-start">
                                        <Grid item xs={12} md={6} className={classes.containerFirstImgCrib}>
                                            <Slide direction="right"
                                                   in={hookFirstSectionFirstTextOurCrib || hookFirstSectionThirdTextOurCrib}
                                                   timeout={theme.speed.animation}>
                                                <img src={firstImageOfCrid} className={classes.imgCribFirst}
                                                     alt={"Intérieur"}/>
                                            </Slide>

                                        </Grid>
                                        <Grid item xs={12} md={6} className={classes.containerTextTitleCrib}>
                                            <Slide direction="left" in={hookFirstSectionFirstTextOurCrib}
                                                   timeout={theme.speed.animation}>
                                                <div ref={refFirstSectionFirstTextOurCrib}>


                                                    <Typography variant="h4" component="h3"
                                                                className={classes.underTitleCrib}>
                                                        Qui sommes-nous ?
                                                    </Typography>
                                                    <hr className={classes.hrUnderTitleOurCrib}/>
                                                    <Typography variant="h5" className={classes.textTitleCrib}>
                                                        Nous sommes une micro crèche créée pour
                                                        subvenir
                                                        aux besoins de garde d'enfants des parents travaillant en
                                                        horaires
                                                        atypiques 6h30 / 21h30. La micro crèche assure pendant la
                                                        journée l'accueil
                                                        collectif
                                                        régulier et
                                                        occasionnel des enfants âgés de 8 semaines à 3 ans.
                                                    </Typography>
                                                </div>
                                            </Slide>
                                            <br/>
                                            <Slide direction="left" in={hookFirstSectionSecondTextOurCrib}
                                                   timeout={theme.speed.animation}>
                                                <div ref={refFirstSectionSecondTextOurCrib}>
                                                    <Typography variant="h4" component="h3"
                                                                className={classes.underTitleCrib}>
                                                        Pourquoi nous choisir ?
                                                    </Typography>
                                                    <hr className={classes.hrUnderTitleOurCrib}/>
                                                    <Typography variant="h5" className={classes.textTitleCrib}>
                                                        Implantée au coeur d'une zone
                                                        industrielle, proche de la rocade, d'accès facile et rapide. Le
                                                        concept de
                                                        micro crèche (accueil de 10 enfants maximum) permet à l'enfant
                                                        de bénéficier
                                                        de la vie en collectivité et d'une prise en charge
                                                        personnalisée.
                                                    </Typography>
                                                </div>
                                            </Slide>
                                            <br/>
                                            <Slide direction="left" in={hookFirstSectionThirdTextOurCrib}
                                                   timeout={theme.speed.animation}>
                                                <div ref={refFirstSectionThirdTextOurCrib}>
                                                    <Typography variant="h4" component="h3"
                                                                className={classes.underTitleCrib}>
                                                        Nos compétences
                                                    </Typography>
                                                    <hr className={classes.hrUnderTitleOurCrib}/>
                                                    <Typography variant="h5" className={classes.textTitleCrib}>
                                                        L'équipe est constituée d'une animatrice d'éveil, d'une auxiliaire puéricultrice et de deux
                                                        infirmières dont
                                                        une référente technique. Tout le personnel de notre micro-crèche
                                                        (y compris
                                                        la gérante) a suivi la formation de Prévention
                                                        et de Secours Civique de niveau 1 dispensée par l'Association
                                                        des Formateurs
                                                        Sapeurs-Pompiers du Douaisis.
                                                    </Typography>
                                                </div>

                                            </Slide>

                                        </Grid>
                                        <Grid item xs={12} md={6}
                                              className={clsx(classes.containerTextTitleCrib, classes.containerTextTitleCribSecond)}>
                                            <Slide direction="right" in={hookSecondSectionFirstTextOurCrib}
                                                   timeout={theme.speed.animation}>
                                                <div ref={refSecondSectionFirstTextOurCrib}>
                                                    <Typography variant="h4" component="h3"
                                                                className={classes.underTitleCrib}>
                                                        Le plus d'une micro-crèche
                                                    </Typography>

                                                    <hr className={classes.hrUnderTitleOurCrib}/>
                                                    <Typography variant="h5" className={classes.textTitleCrib}>
                                                        Notre micro crèche a pour but d'offrir à votre enfant un endroit
                                                        serein et
                                                        sécurisé :
                                                        <br/>
                                                        <span className={classes.marginLeft}>-</span> Lui permettant de
                                                        se sociabiliser au contact des
                                                        autres enfants et des adultes qu'il côtoie quotidiennement,
                                                        apprendre les
                                                        règles de vie en collectivité,
                                                        <br/>
                                                        <span className={classes.marginLeft}>-</span> Lui permettant de
                                                        s'épanouir à travers
                                                        différentes activités adaptées à chaque âge (peinture, musique,
                                                        bouteilles
                                                        sensorielles...)
                                                        <br/>
                                                        <span className={classes.marginLeft}>-</span> Lui permettant
                                                        d'éveiller sa curiosité, de
                                                        développer son langage (imagiers, comptines, histoires...),
                                                        d'acquérir une
                                                        autonomie tout en respectant le rythme de chacun et la motricité
                                                        libre.
                                                    </Typography>
                                                </div>
                                            </Slide>
                                        </Grid>
                                        <Grid item xs={12} md={6} className={classes.containerSecondImgCrib}>
                                            <Slide direction="left" in={hookSecondSectionFirstTextOurCrib}
                                                   timeout={theme.speed.animation}>
                                                <img src={secondImageOfCrid} className={classes.imgCribSecond}
                                                     alt={"Seconde"}/>
                                            </Slide>
                                        </Grid>

                                    </Grid>
                                </Container>
                            </div>
                        </div>
                        <Grid container className={classes.firstDivider} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h2" className={classes.firstDividerText}>
                                    N’hésitez pas à nous <span className={classes.contactUsFirstDividerText}
                                                               onClick={() => history.push('/contact')}>contacter</span> !
                                </Typography>
                            </Grid>
                        </Grid>
                        <Container>

                            <Grid container justify="center" alignItems="center" className={classes.gridFindUs}>

                                <Grid item xs={12}>
                                    <Typography variant="h4" component="h3" className={classes.titleFindUs}>
                                        Où nous trouver
                                    </Typography>
                                    <hr className={classes.hrTitleColorThird}/>
                                </Grid>
                                <Grid item xl={7} lg={7} md={8} xs={8}>
                                    <Paper className={classes.paperFindUs}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={5} className={classes.gridDataFindUs}>
                                                <Typography variant="h5" component="h3"
                                                            className={classes.titleFindUsCard}>
                                                    Les P'tits Pieds Dans Les Prés
                                                </Typography>
                                                <hr className={classes.hrTitleGrey}/>

                                                <Typography variant="h6" component="h4"
                                                            className={classes.textFindUsCard}>
                                                    RUE RENE PANHARD
                                                    <br/>
                                                    59128
                                                    <br/>
                                                    FLERS-EN-ESCREBIEUX
                                                    <br/>
                                                    <br/>
                                                    isabcourmont@gmail.com
                                                    <br/>
                                                    03.27.91.20.86
                                                </Typography>

                                            </Grid>
                                            <Grid item md={7} xs={12} className={classes.containerImgFindUs}>
                                                <img src={logo} alt={"Logo"} className={classes.imgFindUs}/>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>


                            </Grid>
                        </Container>
                        <Footer/>
                    </div>}
        </div>
    )
}