import React from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";

export default function ManageUser(props) {

    const classes = useStyles();

    const [allUsers, setAllUsers] = React.useState([])
    const [allUsersFilter, setAllUsersFilter] = React.useState([])

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("")
    const [severity, setSeverity] = React.useState("")

    const firstNameFilter = React.createRef()
    const lastNameFilter = React.createRef()
    const emailFilter = React.createRef()


    const handleFilter = () => {


        let result = allUsers.filter(elem => {

            return (
                firstNameFilter.current.value.length === 0 || (firstNameFilter.current.value === elem.firstName && firstNameFilter.current.value.length > 0) ?
                    lastNameFilter.current.value.length === 0 || (lastNameFilter.current.value === elem.lastName && lastNameFilter.current.value.length > 0) ?
                        emailFilter.current.value.length === 0 || (emailFilter.current.value === elem.email && emailFilter.current.value.length > 0)

                        :
                        false
                    :
                    false
            )

        })

        setAllUsersFilter(result)
    };


    React.useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/api/user/getAllUsers", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then((response) => {
                props.setSpinnerValue(0);
                setAllUsers([...response])
                setAllUsersFilter([...response])
            })
            .catch(function (error) {

                props.setSpinnerValue(0)
                error.json().then((body) => {

                });
            });
    }, []);

    const handleResetPassword = (elem, i) => {
        const data = {
            id: elem._id
        };
        props.setSpinnerValue(1);
        fetch(process.env.REACT_APP_API_URL + "/api/user/resetPassword", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return Promise.reject(response);
            }
        }).then((response) => {
            props.setSpinnerValue(0);
            setSeverity("success")
            setMessage('Nouveau mot de passe : '+response.password)
            setOpen(true);

        })
            .catch(function (error) {

                props.setSpinnerValue(0)
                error.json().then((body) => {
                    setMessage('Erreur')
                    setOpen(true);
                    setSeverity("error")
                });
            });
    }

    const handleDelete = (elem, i) => {
        const data = {
            id: elem._id
        };
        props.setSpinnerValue(1);
        fetch(process.env.REACT_APP_API_URL + "/api/user/deleteUser", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then((response) => {

                setSeverity("success")
                setMessage('Utilisateur supprimé')
                setOpen(true);

                setAllUsers(temp => {
                    temp.splice(temp.findIndex(elem => elem._id === data.id), 1)

                    return temp;
                });
                setAllUsersFilter(temp2 => {
                    temp2.splice(i, 1)
                    return temp2;
                });
                props.setSpinnerValue(0);


            })
            .catch(function (error) {

                props.setSpinnerValue(0)
                error.json().then((body) => {
                    setMessage('Erreur')
                    setOpen(true);
                    setSeverity("error")
                });
            });
    };

    const handleClose = async (e, reason) => {
        if (reason !== 'clickaway') {
            setOpen(false)
        }
    };


    return (
        <div className={classes.root}>
            <Typography variant="h5" component="h1" className={classes.title}>
                Gérer les utilisateurs
            </Typography>
            <Paper className={classes.paperFilter}>
                <Grid container spacing={3} className={classes.gridContainerField} justify={"space-around"}>
                    <Grid item>
                        <TextField label="Nom" placeholder="Nom" inputRef={lastNameFilter}/>
                    </Grid>
                    <Grid item>
                        <TextField label="Prénom" placeholder="Prénom" inputRef={firstNameFilter}/>
                    </Grid>
                    <Grid item>
                        <TextField label="Email" placeholder="Email" inputRef={emailFilter}/>
                    </Grid>
                </Grid>


                <Grid container justify={"center"} spacing={3}>
                    <Grid item>
                        <Button variant={"contained"} color={"primary"} className={classes.buttonFilter}
                                onClick={handleFilter}>
                            Filtrer
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"} color={"secondary"} className={classes.buttonReset}
                                onClick={() => {
                                    firstNameFilter.current.value = "";
                                    lastNameFilter.current.value = "";
                                    emailFilter.current.value = "";
                                    setAllUsersFilter(allUsers)
                                }}>
                            Réinitialiser
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <div className={classes.containerAllUsers}>
                {
                    allUsersFilter.map((elem, i) =>
                        <Paper elevation={2} className={classes.paperUser} key={i}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant={"body1"} component="span" className={classes.userFirstName}>
                                        {elem.firstName + " "}
                                    </Typography>
                                    <Typography variant={"body1"} component="span" className={classes.userLastName}>
                                        {elem.lastName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"body1"} component="span" className={classes.userFirstName}>
                                        {elem.email + " "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.allButton}>
                                        <Button onClick={() => handleDelete(elem, i)} variant="contained"
                                                className={classes.buttonDelete}> Supprimer </Button>
                                        <Button onClick={() => handleResetPassword(elem, i)} variant="contained"
                                                className={classes.buttonResetPassword}> Reset mot de passe </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    )
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    autoHideDuration={6000}
                    open={open}
                    onClose={handleClose}>

                    <Alert severity={severity} onClose={handleClose}>
                        {message}
                    </Alert>

                </Snackbar>
            </div>
        </div>
    )
}