import React from "react";
import Header from "../Header/header";
import AcceptCGU from "../AcceptCGU/acceptCGU";
import Footer from "../Footer/footer";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";
import {ContextAuth} from "../../Services/useAuth";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {useTheme} from "@material-ui/core";
import Spinner from "../Spinner/spinner";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import GetAppIcon from '@material-ui/icons/GetApp';
export default function Photos() {

    const classes = useStyles();
    const auth = ContextAuth();
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    const theme = useTheme();
    const [spinnerValue, setSpinnerValue] = React.useState(true)
    const [allTheme, setAllTheme] = React.useState({})


    const getTheme = () => {
        setSpinnerValue(true)
        fetch(process.env.REACT_APP_API_URL + "/api/theme/getTheme", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then((response) => {
                setAllTheme(response)


                setSpinnerValue(false)
            })
            .catch(function (error) {
                setSpinnerValue(false)
            });

    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 5,
            partialVisibilityGutter: 40
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
            partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2,
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            partialVisibilityGutter: 40
        }
    };


    React.useEffect(() => {
        getTheme()
    }, []);


    React.useEffect(() => {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                });
            }

            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        }, []
    );


    return (
        <div>
            {
                spinnerValue ? <Spinner loading={spinnerValue} color={theme.palette.primary.main}/> :
                    <div>
                        <Header width={dimensions.width} auth={auth}/>
                        {
                            dimensions.width >= theme.breakpoints.values.md ?
                                <div>

                                    <div className={classes.containerImgLogo}>

                                    </div>
                                </div>
                                :
                                <div>
                                    <div className={classes.containerImgLogoPhone}>

                                    </div>
                                </div>
                        }
                        <Container className={classes.container}>
                            <Typography variant="h4" component="h1" className={classes.title}>
                                Photos
                            </Typography>
                            {
                                allTheme.reverse().map((themeParsed, index) => (
                                    <div>
                                        {
                                            themeParsed.allPhotos.length !== 0 ?

                                                <div>
                                                    <Typography variant="h4" component="h1">
                                                        {themeParsed.name}
                                                    </Typography>
                                                    <Carousel responsive={responsive}
                                                              infinite={true}
                                                              autoPlaySpeed={1000}
                                                              keyBoardControl={true}
                                                              partialVisible
                                                              showDots={false}
                                                              sliderClass=""
                                                              slidesToSlide={1}
                                                              swipeable
                                                              additionalTransfrom={40}
                                                                className={classes.carousel}

                                                    >
                                                        {
                                                            themeParsed.allPhotos.map((photos, index2) => (

                                                                <div className={classes.containerDiv}>
                                                                    <a  className={classes.download} target="_blank"   download href={process.env.REACT_APP_API_URL + "/api/photos/getPhotoById?id=" + photos}>
                                                                        <img alt={"Chargement"}
                                                                             className={classes.img}
                                                                             src={process.env.REACT_APP_API_URL + "/api/photos/getPhotoById?id=" + photos}
                                                                        />
                                                                        <div className={classes.middle}>
                                                                           <GetAppIcon/>
                                                                        </div>
                                                                    </a>


                                                                </div>
                                                            ))
                                                        }


                                                    </Carousel>
                                                <hr/>
                                                </div>
                                                : ""
                                        }</div>

                                ))
                            }
                        </Container>

                        <Footer/>
                    </div>


            }

        </div>
    )
}

