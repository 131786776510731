import React from 'react';
import {Route, Redirect,useLocation } from 'react-router-dom';
import {ContextAuth} from "../../Services/useAuth";
import Spinner from "../Spinner/spinner";
import {useTheme} from "@material-ui/core/styles";


const PrivateRoute = ({component: Component, userAccept, routeRedirect, ...rest}) => {

    const theme = useTheme();
    const auth = ContextAuth();
    const location = useLocation();


    return (
        <Route {...rest} render={props => (
            auth.state.isLoading ? <Spinner loading={true} color={theme.palette.primary.main}/>
                :
                auth.state.user === null ? <Redirect to={routeRedirect}/> :
                    auth.state.user.isFirstConnection  && location.pathname!='/changePassword' ? <Redirect to={'/changePassword'}/> :
                        userAccept.includes(auth.state.user.type) ?
                            <Component {...props} />
                            : <Redirect to={routeRedirect}/>
        )}/>
    );
};

export default PrivateRoute;