export const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                user: action.payload.user,
                isAuthenticated: true,
                isLoading: false,
            };
        case "NOT CONNECTED":
            return {
                ...state,
                user: null,
                isAuthenticated: false,
                isLoading: false,
            };
        default:
            return state;
    }
};
