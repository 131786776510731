import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    popperConnection: {
        zIndex: '1001',
    },
    container: {
        padding: '15px 10px 25px 10px',
        width: '250px',
    },
    textFieldSecond: {
        marginTop: '15px !important',
        width: '100%',
    },
    textFieldFirst: {
        marginTop: '25px !important',
        width: '100%',
    },
    title: {
        textAlign: 'center',
        fontFamily: theme.font.annieUseYourTelescope + " !important",
    },
    buttonSend: {
        marginTop: '30px !important',
        width: '100%',
    },
    containerError:{
      marginTop:'15px !important',
    },
}));

export default useStyles;