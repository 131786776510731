import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        backgroundColor: theme.palette.primary.main + "E6",
        margin: 'auto',
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
    },
    link: {
        cursor: 'pointer',
        textAlign: 'center',
        fontFamily: theme.font.fugazOne + ' !important',
        color: 'white',
        whiteSpace:'nowrap',
        '&:hover': {
            backgroundRepeat: ' no-repeat',
            backgroundPosition: 'center bottom',
            backgroundImage: 'linear-gradient(' + theme.palette.primary.main + ',' + theme.palette.primary.main + ') !important',
            animation: "0.5s $slideUnderline forwards",
        },
    },
    underline: {
        cursor: 'pointer',
        textAlign: 'center',
        fontFamily: theme.font.fugazOne + ' !important',
        color: 'white',
        backgroundRepeat: ' no-repeat',
        backgroundPosition: 'center bottom',
        backgroundImage: 'linear-gradient(white,white) !important',
        backgroundSize: '50px 3px',
    },
    gridContainerOfLink: {
        paddingTop: '15px',
        paddingBottom: '15px',


    },

    gridContainerOfLinkAnimation: {
        paddingTop: '15px',
        paddingBottom: '15px',
        backgroundSize: '100% 200%',
        backgroundImage: 'linear-gradient(to bottom, transparent 50%, #000000B3 50%)',
        transition: 'background-position 0.5s',
        '&:hover': {
            backgroundPosition: '0 100%',
        }
    },


    '@keyframes slideUnderline': {
        from: {
            backgroundSize: '0px 3px',
        },
        to: {
            backgroundSize: '50px 3px',
        }
    },


}));

export default useStyles;