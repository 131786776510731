import React from "react";
import useStyles from "./style";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {useHistory, useLocation} from "react-router";

export default function NavPhone(props) {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();



    return (
        <div className={classes.container}>
            <div  className={classes.containerGrid}>
                <Grid container  direction={"column"} justify={"space-around"} >
                    <Grid item xs={2}>
                        <Typography variant="h6" component="h3"  className={clsx( (location.pathname==='/home' || location.pathname==='/') && classes.underline , classes.link)} onClick={()=>history.push('/home')}>
                            Accueil
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h6" component="h3"   className={clsx( location.pathname==='/contact' && classes.underline , classes.link)} onClick={()=>history.push('/contact')}>
                            Contact
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h6" component="h3"   className={clsx( location.pathname==='/rate' && classes.underline , classes.link)} onClick={()=>history.push('/rate')}>
                            Tarif
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h6" component="h3"   className={clsx( location.pathname==='/signUp' && classes.underline , classes.link)} onClick={()=>history.push('/signUp')}>
                            Pré-Inscription
                        </Typography>
                    </Grid>
                    {
                        props.auth.state.isAuthenticated &&

                        <Grid item xs={2} >
                            <Typography variant="h6" component="h3" className={clsx( location.pathname==='/photos' && classes.underline , classes.link)} onClick={()=>history.push('/photos')}>
                                Voir les Photos
                            </Typography>
                        </Grid>
                    }
                    {
                        props.auth.state.user !== null && props.auth.state.user.type==="admin" &&

                        <Grid item xs={2} >
                            <Typography variant="h6" component="h3" className={clsx( location.pathname==='/dashboardAdmin' && classes.underline , classes.link)} onClick={()=>history.push('/dashboardAdmin')}>
                                Admin
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </div>

        </div>
    )
}