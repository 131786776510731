import React from "react";
import {ContextAuth} from "../../Services/useAuth";
import useTheme from "@material-ui/core/styles/useTheme";
import AcceptCGU from "../AcceptCGU/acceptCGU";
import Header from "../Header/header";
import Typography from "@material-ui/core/Typography";
import Footer from "../Footer/footer";
import useStyles from "./style";
import Container from "@material-ui/core/Container";
import {
    useHistory
} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {OutlinedInput, InputAdornment, IconButton} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Spinner from "../Spinner/spinner";

export default function ChangePassword() {

    const auth = ContextAuth();
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();



    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmedNewPassword, setShowConfirmedNewPassword] = React.useState(false);

    const regexPassword=new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\\w\\s]).{8,}$')


    const refOldPassword = React.createRef()
    const refNewPassword = React.createRef()
    const refConfirmedNewPassword = React.createRef()

    const [spinnerValue, setSpinnerValue] = React.useState(0)


    const [errorForm, setErrorForm] = React.useState({
        newPassword: "",
        confirmNewPassword: "",
        actualPassword: "",

    });

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    React.useEffect(() => {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                });
            }

            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        }
    );

    if (!auth.state.isLoading) {
        if (!auth.state.user.isFirstConnection) {
            history.push('/')
        }
    }



    const handleSubmit = (e) => {

        e.preventDefault()



        setSpinnerValue(true)

        let errorForm = {
            newPassword: "",
            confirmNewPassword: "",
            actualPassword: "",
        };

        let error= false;

        if (refNewPassword.current.value === "") {
            errorForm.newPassword = "Veuillez remplir ce champ"
            error =true;
        }
        if (refConfirmedNewPassword.current.value === "") {
            errorForm.confirmNewPassword = "Veuillez remplir ce champ"
            error =true;
        }
        if (refOldPassword.current.value === "") {
            errorForm.actualPassword = "Veuillez remplir ce champ"
            error =true;
        }
        if (refNewPassword.current.value !==refConfirmedNewPassword.current.value) {
            errorForm.newPassword = "Les deux mots de passes ne correspondent pas"
            errorForm.confirmNewPassword = "Les deux mots de passes ne correspondent pas"
            error =true;
        }
        if(!regexPassword.test(refNewPassword.current.value)){

            errorForm.newPassword = "Votre mot de passe doit contenir au moins 1 minuscule 1 majuscule 1 caractère spécial 1 nombre et doit avoir au moins 8 caractères"
            error =true;
        }

        if(error){
            setSpinnerValue(false)
            setErrorForm(errorForm)
            return;
        }


        setErrorForm({
            newPassword: "",
            confirmNewPassword: "",
            actualPassword: "",

        });


        fetch(process.env.REACT_APP_API_URL + "/api/user/changePassword", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                    newPassword: refNewPassword.current.value,
                    confirmNewPassword: refConfirmedNewPassword.current.value,
                    actualPassword: refOldPassword.current.value,
                }
            )
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then((response) => {
                setSpinnerValue(false)
                auth.handleAuthentication();

            })
            .catch(function (error) {
                setSpinnerValue(false)

                error.json().then((body) => {
                    if(body.message==="Incorrect credentials"){
                        setErrorForm({
                            newPassword: "",
                            confirmNewPassword: "",
                            actualPassword: "Le mot de passe ne correspond pas à votre actuel mot de passe",
                        });
                    }
                });
                return error;
            });
    };

    return (
        <div>
            {
                auth.state.isLoading ? ""
                    :

                    <div>
                        <Spinner loading={spinnerValue} color={theme.palette.primary.main}/>
                        <Header width={dimensions.width} auth={auth}/>
                        <Container>
                            <Typography variant="h4" component="h1" className={classes.title}>
                                Changer votre mot de passe
                            </Typography>
                            <hr className={classes.hrTitle}/>
                            <Typography variant="h5" component="h1" className={classes.underTitle}>
                                Veuillez modifier votre mot de passe avant de continuer la navigation sur notre site
                            </Typography>
                            <form onSubmit={handleSubmit}>


                                <Grid container spacing={2} justify={"center"} className={classes.containerForm}>

                                    <Grid item xs={12}>
                                        <FormControl  error={Boolean(errorForm.actualPassword)} className={classes.formControl}>
                                            <OutlinedInput variant="outlined"
                                                           type={showOldPassword ? 'text' : 'password'}
                                                           inputRef={refOldPassword}
                                                           placeholder="Votre ancien mot de passe *"
                                                           align="start"
                                                           endAdornment={
                                                               <InputAdornment position="end">
                                                                   <IconButton
                                                                       onClick={() => {
                                                                           setShowOldPassword(!showOldPassword)
                                                                       }}
                                                                       edge="end"
                                                                   >
                                                                       {showOldPassword ? <Visibility/> :
                                                                           <VisibilityOff/>}
                                                                   </IconButton>
                                                               </InputAdornment>
                                                           }
                                            />
                                            <FormHelperText id="component-error-text">{errorForm.actualPassword}</FormHelperText>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl error={Boolean(errorForm.newPassword)} className={classes.formControl}>
                                            <OutlinedInput variant="outlined"
                                                           type={showNewPassword ? 'text' : 'password'}
                                                           placeholder="Votre nouveau mot de passe *"

                                                           inputRef={refNewPassword}
                                                           align="start"
                                                           endAdornment={
                                                               <InputAdornment position="end">
                                                                   <IconButton
                                                                       onClick={() => {
                                                                           setShowNewPassword(!showNewPassword)
                                                                       }}
                                                                       edge="end"
                                                                   >
                                                                       {showNewPassword ? <Visibility/> :
                                                                           <VisibilityOff/>}
                                                                   </IconButton>
                                                               </InputAdornment>
                                                           }
                                            />
                                            <FormHelperText id="component-error-text">{errorForm.newPassword}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl error={Boolean(errorForm.confirmNewPassword)} className={classes.formControl}>
                                            <OutlinedInput variant="outlined"
                                                           type={showConfirmedNewPassword ? 'text' : 'password'}
                                                           placeholder="Confirmation de votre nouveau mot de passe *"
                                                           inputRef={refConfirmedNewPassword}
                                                           className={classes.textField}
                                                           align="start"
                                                           endAdornment={
                                                               <InputAdornment position="end">
                                                                   <IconButton
                                                                       onClick={() => {
                                                                           setShowConfirmedNewPassword(!showConfirmedNewPassword)
                                                                       }}
                                                                       edge="end"
                                                                   >
                                                                       {showConfirmedNewPassword ? <Visibility/> :
                                                                           <VisibilityOff/>}
                                                                   </IconButton>
                                                               </InputAdornment>
                                                           }
                                            />
                                            <FormHelperText id="component-error-text">{errorForm.confirmNewPassword}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type={"submit"} variant="contained"> Envoyer </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                        <Footer/>
                    </div>
            }

            <AcceptCGU/>

        </div>

    )

}
;