import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router";
import useStyles from "./style";
import Cookies from 'js-cookie';


export default function AcceptCGU(){
    let obj ={ acceptCGU : false , rememberConnection : false };
    if(Cookies.get('rememberme')){
        obj = JSON.parse(Cookies.get('rememberme'))
    }

    const [open, setOpen] = React.useState(!obj.acceptCGU);
    const history = useHistory();
    const handleClose = async (e,reason)=>{
        if (reason !== 'clickaway') {
            setOpen(false)
        }
    };

    const handleCloseAndAccept = async (e,reason)=>{

        await fetch(process.env.REACT_APP_API_URL + "/api/user/acceptCGU", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {

                    return response.json();
                } else {

                    throw new Error('Error');
                }
            })
        if (reason !== 'clickaway') {
            setOpen(false)
        }
    };
    const classes = useStyles();






    return (
        obj.acceptCGU===false ?

        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            onClose={handleClose}
            className={classes.snackbar}
            message="En poursuivant votre navigation sur ce site, vous acceptez nos conditions générales d'utilisation"
            action={
                <React.Fragment>
                    <Button color="secondary" size="small" onClick={()=> history.push('/CGU')}>
                        En savoir plus
                    </Button>
                    <Button color="secondary" size="small" onClick={handleCloseAndAccept}>
                        Accepter
                    </Button>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }
        />
        :""
    )
}