import React from "react";
import useStyles from "./style";
import Header from "../Header/header";
import AcceptCGU from "../AcceptCGU/acceptCGU";
import Footer from "../Footer/footer";
import Typography from "@material-ui/core/Typography";
import fileSignUp from '../../File/Inscription.pdf'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from "@material-ui/core/Container";
import KidAlreadyBorn from "./kidAlreadyBorn/kidAlreadyBorn";
import AlwaysPregnant from "./alwaysPregnant/alwaysPregnant";
import {ContextAuth} from "../../Services/useAuth";

export default function Photos() {

    const auth = ContextAuth();
    const classes = useStyles();
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });


    React.useEffect(() => {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                });
            }

            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        }
    );
    return (
        <div>
            {
                auth.state.isLoading ? "" :
                    <div>
                        <AcceptCGU/>
                        <Header width={dimensions.width} auth={auth}/>
                        <Typography variant="h4" component="h1" className={classes.title}>
                            Pré-Inscription
                        </Typography>
                        <hr className={classes.hrTitle}/>
                        <Container maxWidth={"md"} className={classes.containerContent}>
                            <Typography className={classes.typoFooter}>
                                Vous pouvez aussi pré-inscrire votre enfant en téléchargeant <a
                                className={classes.fileSignUp}
                                href={fileSignUp} download> ce
                                fichier </a> et l’envoyer par mail à isabcourmont@gmail.com
                            </Typography>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h5" component="h2" className={classes.heading}>Si votre enfant
                                        est déjà
                                        né </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <KidAlreadyBorn/>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant="h5" component="h2" className={classes.heading}>Si vous êtes
                                        enceinte </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AlwaysPregnant/>
                                </AccordionDetails>
                            </Accordion>

                        </Container>


                        <Footer/>
                    </div>
            }
        </div>
    )
}