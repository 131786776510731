import React from "react";
import useStyles from "./style";
import {ContextAuth} from "../../../Services/useAuth";
import useTheme from "@material-ui/core/styles/useTheme";
import Spinner from "../../Spinner/spinner";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {KeyboardTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

function TextFieldModify(props) {
    const classes = useStyles();
    return (
        <div className={classes.containerComponentModified}>
            <Typography variant="body1" className={classes.titleModify}>
                {props.title}
            </Typography>
            <TextField helperText={props.helperText} error={props.error} inputRef={props.inputRef} variant="outlined"
                       className={classes.textFieldModify}/>
        </div>
    )
}


function TextFieldDateModify(props) {
    const classes = useStyles();
    return (
        <div className={classes.containerComponentModified}>
            <Typography variant="body1" className={classes.titleModify}>
                {props.title}
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker invalidDateMessage={"Date invalide"} format="DD/MM/yyyy" variant="outlined"
                                    placeholder="10/10/2018" value={props.value}
                                    onChange={date => props.handleChange(date)}
                                    className={classes.textFieldDateModify}/>
            </MuiPickersUtilsProvider>

        </div>
    )
}

function TextFieldTimeModify(props) {
    const classes = useStyles();

    return (
        <div className={classes.containerComponentModified}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardTimePicker invalidDateMessage={"Horaire invalide"} ampm={false} format={"HH:mm"} mask="__:__"
                                    helperText={props.helperText} error={props.error}
                                    placeholder="08:00" onChange={(e) => props.onChange(props.i, props.j, e)}
                                    value={props.value} className={classes.timeModify}/>
            </MuiPickersUtilsProvider>
        </div>

    )
}

export default function AlwaysPregnant() {

    const classes = useStyles();


    const regexMail = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
    const auth = ContextAuth();
    const theme = useTheme()
    const allDay = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"]


    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    const [errorForm, setErrorForm] = React.useState({
        nameFamilialKid: '',

        nameFather: '',
        firstNameFather: '',
        telPhoneFather: '',
        telWorkFather: '',
        telCarryFather: '',
        emailFather: '',
        placeWorkFather: '',
        workFather: '',
        adressFather: '',
        regFather: '',



        nameMother: '',
        firstNameMother: '',
        telPhoneMother: '',
        telWorkMother: '',
        telCarryMother: '',
        phoneMother: '',
        emailMother: '',
        placeWorkMother: '',
        workMother: '',
        adressMother: '',
        regMother: '',

        allDaySelected: '',
        arrayTimeWanted: Array(2).fill('').map(() => Array(5).fill(''))
    });
    const [allDayselected, setAllDaysSelected] = React.useState([])
    const [valueArrayTimeWanted, setValueArrayTimeWanted] = React.useState(Array(2).fill(null).map(() => Array(5).fill(null)));
    const [valueArrayTimeTotal, setValueArrayTimeTotal] = React.useState([null, null, null, null, null]);
    const [valueRadioCustody, setValueRadioCustody] = React.useState("");
    const [valueRadioFamilialSituation, setValueRadioFamilialSituation] = React.useState('maries');
    const [valueRadioWantedContract, setValueRadioWantedContract] = React.useState('occasionnel');
    const [selectedDateDelivery, handleDateChangeDelivery] = React.useState(new Date());
    const [selectedDateEnterCrib, handleDateChangeEnterCrib] = React.useState(new Date());
    const [checkedCertificate, setCheckedCertificate] = React.useState(false);

    const recaptchaRef = React.useRef();
    const refNameKid = React.useRef("");

    const refNameFather = React.useRef("");
    const refFirstNameFather = React.useRef("");
    const refTelPhoneFather = React.useRef("");
    const refTelWorkFather = React.useRef("");
    const refTelCarryFather = React.useRef("");
    const refEmailFather = React.useRef("");
    const refPlaceWorkFather = React.useRef("");
    const refAdressFather = React.useRef("");
    const refTextFieldInfoExplicationPlanning = React.useRef("");
    const refWorkFather = React.useRef("");
    const refRegFather = React.useRef();

    const refNameAlone = React.useRef("");
    const refFirstNameAlone = React.useRef("");
    const refTelPhoneAlone = React.useRef("");
    const refTelWorkAlone = React.useRef("");
    const refTelCarryAlone = React.useRef("");
    const refEmailAlone = React.useRef("");
    const refPlaceWorkAlone = React.useRef("");
    const refAdressAlone = React.useRef("");
    const refWorkAlone = React.useRef("");
    const refRegAlone = React.useRef("");

    const refNameMother = React.useRef("");
    const refFirstNameMother = React.useRef("");
    const refTelPhoneMother = React.useRef("");
    const refTelWorkMother = React.useRef("");
    const refTelCarryMother = React.useRef("");
    const refEmailMother = React.useRef("");
    const refPlaceWorkMother = React.useRef("");
    const refAdressMother = React.useRef("");
    const refWorkMother = React.useRef("");
    const refRegMother = React.useRef("");


    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [spinnerValue, setSpinnerValue] = React.useState(0)
    const [messageSnackbar, setMessageSnackBar] = React.useState("")
    const [severity, setSeverity] = React.useState("")

    const handleSubmit = async (e) => {

        setSpinnerValue(1)

        e.preventDefault();
        let error = false;


        let tempError = {
            nameFamilialKid: '',

            nameFather: '',
            firstNameFather: '',
            telPhoneFather: '',
            telWorkFather: '',
            telCarryFather: '',
            emailFather: '',
            placeWorkFather: '',
            workFather: '',
            adressFather: '',
            regFather: '',



            nameMother: '',
            firstNameMother: '',
            telPhoneMother: '',
            telWorkMother: '',
            telCarryMother: '',
            phoneMother: '',
            emailMother: '',
            placeWorkMother: '',
            workMother: '',
            adressMother: '',
            regMother: '',

            allDaySelected: '',
            arrayTimeWanted: Array(2).fill('').map(() => Array(5).fill(''))
        }
        setErrorForm(tempError)

        let nameFamilialKid = refNameKid.current.value;

        let nameFather = refNameFather.current.value;
        let firstNameFather = refFirstNameFather.current.value;
        let telPhoneFather = refTelPhoneFather.current.value;
        let telWorkFather = refTelWorkFather.current.value;
        let telCarryFather = refTelCarryFather.current.value;
        let emailFather = refEmailFather.current.value;
        let placeWorkFather = refPlaceWorkFather.current.value;
        let adressFather = refAdressFather.current.value;
        let workFather = refWorkFather.current.value;
        let regFather = refRegFather.current.value;

        let nameMother = refNameMother.current.value;
        let firstNameMother = refFirstNameMother.current.value;
        let telPhoneMother = refTelPhoneMother.current.value;
        let telWorkMother = refTelWorkMother.current.value;
        let telCarryMother = refTelCarryMother.current.value;
        let emailMother = refEmailMother.current.value;
        let placeWorkMother = refPlaceWorkMother.current.value;
        let adressMother = refAdressMother.current.value;
        let workMother = refWorkMother.current.value;
        let regMother = refRegMother.current.value;

        let textFieldInfoExplicationPlanning= refTextFieldInfoExplicationPlanning.current.value

        if (nameFamilialKid === '') {
            tempError.nameFamilialKid = 'Veuillez renseigner ce champ'
            error = true;
        }

        if (valueRadioFamilialSituation === "veuf" ) {
            let errorFather = false;
            let errorMother = false;
            
            let notFullFilledFather = false;
            let notFullFilledMother = false;
            
            if (nameFather === "") {
                errorFather = true;
            }
            if (firstNameFather === "") {
                errorFather = true;
                if(errorFather){
                    notFullFilledFather = true
                }
            }
            if (telPhoneFather === "") {
                errorFather = true;
                if(errorFather){
                    notFullFilledFather = true
                }
            }
            if (telWorkFather === "") {
                errorFather = true;
                if(errorFather){
                    notFullFilledFather = true
                }
            }
            if (telCarryFather === "") {
                errorFather = true;
                if(errorFather){
                    notFullFilledFather = true
                }
            }
            if (emailFather === "") {
                errorFather = true;
                if(errorFather){
                    notFullFilledFather = true
                }
            }
            if (placeWorkFather === "") {
                errorFather = true;
                if(errorFather){
                    notFullFilledFather = true
                }
            }
            if (adressFather === "") {
                errorFather = true;
                if(errorFather){
                    notFullFilledFather = true
                }
            }
            if (workFather === "") {
                errorFather = true;
                if(errorFather){
                    notFullFilledFather = true
                }
            }
            if (regFather === "") {
                errorFather = true;
                if(errorFather){
                    notFullFilledFather = true
                }
            }
            if (nameMother === "") {
                errorMother = true;
            }
            if (firstNameMother === "") {
                errorMother = true;
                if(errorMother){
                    notFullFilledMother = true
                }
            }
            if (telPhoneMother === "") {
                errorMother = true;
                if(errorMother){
                    notFullFilledMother = true
                }
            }
            if (telWorkMother === "") {
                errorMother = true;
                if(errorMother){
                    notFullFilledMother = true
                }
            }
            if (telCarryMother === "") {
                errorMother = true;
                if(errorMother){
                    notFullFilledMother = true
                }
            }
            if (emailMother === "") {
                errorMother = true;
                if(errorMother){
                    notFullFilledMother = true
                }
            }
            if (placeWorkMother === "") {
                errorMother = true;
                if(errorMother){
                    notFullFilledMother = true
                }
            }
            if (adressMother === "") {
                errorMother = true;
                if(errorMother){
                    notFullFilledMother = true
                }
            }
            if (workMother === "") {
                errorMother = true;
                if(errorMother){
                    notFullFilledMother = true
                }
            }
            if (regMother === "") {
                errorMother = true;
                if(errorMother){
                    notFullFilledMother = true
                }
            }



            if(errorMother && errorFather){
                error = true;
                tempError.nameFather = 'Veuillez renseigner au moins un parent'
                tempError.firstNameFather = 'Veuillez renseigner au moins un parent'
                tempError.telPhoneFather = 'Veuillez renseigner au moins un parent'
                tempError.telWorkFather = 'Veuillez renseigner au moins un parent'
                tempError.telCarryFather = 'Veuillez renseigner au moins un parent'
                tempError.emailFather = 'Veuillez renseigner au moins un parent'
                tempError.placeWorkFather = 'Veuillez renseigner au moins un parent'
                tempError.adressFather = 'Veuillez renseigner au moins un parent'
                tempError.workFather = 'Veuillez renseigner au moins un parent'
                tempError.regFather = 'Veuillez renseigner au moins un parent'
                tempError.nameMother = 'Veuillez renseigner au moins un parent'
                tempError.firstNameMother = 'Veuillez renseigner au moins un parent'
                tempError.telPhoneMother = 'Veuillez renseigner au moins un parent'
                tempError.telWorkMother = 'Veuillez renseigner au moins un parent'
                tempError.telCarryMother = 'Veuillez renseigner au moins un parent'
                tempError.emailMother = 'Veuillez renseigner au moins un parent'
                tempError.placeWorkMother = 'Veuillez renseigner au moins un parent'
                tempError.adressMother = 'Veuillez renseigner au moins un parent'
                tempError.workMother = 'Veuillez renseigner au moins un parent'
                tempError.regMother = 'Veuillez renseigner au moins un parent'
            }
            
            
        } else {
            if (nameFather === "") {
                tempError.nameFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (firstNameFather === "") {
                tempError.firstNameFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (telPhoneFather === "") {
                tempError.telPhoneFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (telWorkFather === "") {
                tempError.telWorkFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (telCarryFather === "") {
                tempError.telCarryFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (emailFather === "") {
                tempError.emailFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (placeWorkFather === "") {
                tempError.placeWorkFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (adressFather === "") {
                tempError.adressFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (workFather === "") {
                tempError.workFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (regFather === "") {
                tempError.regFather = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (nameMother === "") {
                tempError.nameMother = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (firstNameMother === "") {
                tempError.firstNameMother = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (telPhoneMother === "") {
                tempError.telPhoneMother = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (telWorkMother === "") {
                tempError.telWorkMother = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (telCarryMother === "") {
                tempError.telCarryMother = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (emailMother === "") {
                tempError.emailMother = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (placeWorkMother === "") {
                tempError.placeWorkMother = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (adressMother === "") {
                tempError.adressMother = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (workMother === "") {
                tempError.workMother = 'Veuillez renseigner ce champ'
                error = true;
            }
            if (regMother === "") {
                tempError.regMother = 'Veuillez renseigner ce champ'
                error = true;
            }
        }

        if (allDayselected.length === 0) {
            tempError.allDaySelected = "Veuillez sélectionner au moins 1 journée"
            error = true;
        } else {
            for (let i = 0; i < allDayselected.length; i++) {
                let indexOfDay = dayToIndex(allDayselected[i])
                for (let j = 0; j < 2; j++) {
                    if (valueArrayTimeWanted[j][indexOfDay] == null) {

                        tempError.arrayTimeWanted[j][indexOfDay] = 'Veuillez renseigner ce champ'
                        error = true;
                    } else if (!valueArrayTimeWanted[j][indexOfDay]._isValid) {
                        tempError.arrayTimeWanted[j][indexOfDay] = 'Veuillez renseigner un horaire valide'
                        error = true;
                    }
                }

            }
        }

        if (valueRadioFamilialSituation === "divorces" || valueRadioFamilialSituation === "veuf" || valueRadioFamilialSituation === "celibataire" ) {
            if (valueRadioCustody === "") {
                error = true;
            }
        }
        if (error) {
            setSpinnerValue(0)
            if (valueRadioFamilialSituation === "divorces" || valueRadioFamilialSituation === "veuf" || valueRadioFamilialSituation === "celibataire" ) {
                if (valueRadioCustody === "") {
                    setMessageSnackBar("Veuillez renseigner qui a la garde de l'enfant")
                    setOpenSnackbar(true);
                    setSeverity("error")
                } else {
                    setSpinnerValue(0)
                    setErrorForm(tempError)
                    setMessageSnackBar("Erreur")
                    setOpenSnackbar(true);
                    setSeverity("error")
                }
            } else {
                setSpinnerValue(0)
                setErrorForm(tempError)
                setMessageSnackBar("Erreur")
                setOpenSnackbar(true);
                setSeverity("error")
            }

        } else {
            let captcha = await recaptchaRef.current.executeAsync();



            let value = {
                nameKidAlwaysPregnant: nameFamilialKid,
                selectedDateDeliveryAlwaysPregnant: selectedDateDelivery,
                selectedDateEnterCribAlwaysPregnant: selectedDateEnterCrib,
                captcha: captcha,
                firstNameKidAlreadyBorn: "",
                genderAlreadyBorn: "",
                selectedDateDeliveryAlreadyBorn: "",
                selectedDateEnterCribAlreadyBorn: "",
                nameKidAlreadyBorn: '',
                arrayTimeWanted: valueArrayTimeWanted,
                valueArrayTimeTotal: valueArrayTimeTotal,
                wantedContract: valueRadioWantedContract,
                nameFather: nameFather,
                firstNameFather: firstNameFather,
                telPhoneFather: telPhoneFather,
                telWorkFather: telWorkFather,
                telCarryFather: telCarryFather,
                emailFather: emailFather,
                workFather: workFather,
                placeWorkFather: placeWorkFather,
                regFather: regFather,
                adressFather: adressFather,
                nameMother: nameMother,
                firstNameMother: firstNameMother,
                telPhoneMother: telPhoneMother,
                telWorkMother: telWorkMother,
                telCarryMother: telCarryMother,
                emailMother: emailMother,
                workMother: workMother,
                placeWorkMother: placeWorkMother,
                regMother: regMother,
                adressMother: adressMother,
                radioButtonSituation: valueRadioFamilialSituation,
                radioButtonCustody: valueRadioCustody,
                textFieldInfoExplicationPlanning:textFieldInfoExplicationPlanning
            };


            fetch(process.env.REACT_APP_API_URL + "/api/mail/sendInscription", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify({
                    value
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return Promise.reject(response);
                    }
                })
                .then(() => {

                    setSpinnerValue(0);
                    setMessageSnackBar('Pre-inscription envoyée')
                    setSeverity("success")
                    setOpenSnackbar(true);

                })
                .catch(function (error) {

                    setSpinnerValue(0);
                    setMessageSnackBar('Erreur de validation du captcha')
                    setOpenSnackbar(true);
                    setSeverity("error")

                })


        }

        //FOR FATHER
    }


    const handleRadioCustody = (e) => {
        if (e.target.value === valueRadioCustody) {
            setValueRadioCustody("")
        } else {
            setValueRadioCustody(e.target.value)
        }
    }
    const handleRadioFamilialSituation = (e, newValue) => {
        setValueRadioFamilialSituation(newValue)
    }

    const handleRadioWantedContract = (e, newValue) => {
        setValueRadioWantedContract(newValue)
    }

    const handleCloseSnackbar = async (e, reason) => {
        if (reason !== 'clickaway') {
            setOpenSnackbar(false)
        }
    };

    function displayDiff(j) {

        if (valueArrayTimeWanted[1][j] !== null && valueArrayTimeWanted[0][j] !== null) {
            if (valueArrayTimeWanted[1][j]._isValid && valueArrayTimeWanted[0][j]._isValid) {

                let diff = valueArrayTimeWanted[1][j].diff(valueArrayTimeWanted[0][j], "minutes")
                var h = diff / 60 | 0,
                    m = diff % 60 | 0;
                handleChangeValueArrayTimeTotal(j, h + (m / 100 / 0.6));
            }
        }

    }

    function dayToIndex(day) {
        return allDay.indexOf(day);
    }

    function handleChangeValueArrayTimeWanted(i, j, newValue) {
        const newArray = valueArrayTimeWanted.slice(0)
        newArray[i][j] = newValue
        setValueArrayTimeWanted(newArray)

        displayDiff(j)
    }


    const handleChangeCertificate = (event) => {
        setCheckedCertificate(!checkedCertificate);
    };

    function handleChangeValueArrayTimeTotal(i, newValue) {
        const newArray = valueArrayTimeTotal.slice(0)
        newArray[i] = parseFloat(newValue.toFixed(2));
        setValueArrayTimeTotal(newArray);
    }

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Spinner loading={spinnerValue || auth.state.isLoading} color={theme.palette.primary.main}/>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={6000}
                open={openSnackbar}
                onClose={handleCloseSnackbar}>

                <Alert severity={severity} onClose={handleCloseSnackbar}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>


            <Grid container>
                <Grid item xs={12} className={classes.section}>
                    <TextFieldModify error={Boolean(errorForm.nameFamilialKid)} helperText={errorForm.nameFamilialKid}
                                     inputRef={refNameKid} title={"Nom de famille de l'enfant a naître"}/>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <TextFieldDateModify value={selectedDateDelivery} handleChange={handleDateChangeDelivery}
                                         title={"Date prévue de l’accouchement"}/>
                    <TextFieldDateModify value={selectedDateEnterCrib} handleChange={handleDateChangeEnterCrib}
                                         title={"Date prévue d’entrée à la micro-crèche"}/>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <Typography variant="body1" component="h2" className={classes.titleModify}>
                        Situation familiale
                    </Typography>
                    <RadioGroup value={valueRadioFamilialSituation} onChange={handleRadioFamilialSituation}
                                className={classes.radioGroup}>
                        <FormControlLabel value="maries" control={<Radio/>} label="Mariés"/>
                        <FormControlLabel value="divorces" control={<Radio/>} label="Divorcés"/>
                        <FormControlLabel value="veuf" control={<Radio/>} label="Veuf(ve)"/>
                        <FormControlLabel value="unionLibre" control={<Radio/>} label="Union libre"/>
                        <FormControlLabel value="PACS" control={<Radio/>} label="PACS"/>
                        <FormControlLabel value="celibataire" control={<Radio/>} label="Célibataire"/>
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <Typography variant="body1" component="h2" className={classes.titleModify}>
                        Si vous êtes séparés ou divorcés qui a la garde de l’enfant :
                    </Typography>
                    <RadioGroup value={valueRadioCustody}

                                onClick={handleRadioCustody}
                                className={classes.radioGroup}>
                        <FormControlLabel control={<Radio/>} value="pere" control={<Radio/>} label="Père"/>
                        <FormControlLabel control={<Radio/>} value="mere" control={<Radio/>} label="Mère"/>
                        <FormControlLabel control={<Radio/>} value="gardeAlterne" control={<Radio/>}
                                          label="Garde alternée"/>
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <hr/>
                </Grid>


                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h2" className={classes.titlePart}>
                            Informations relatives au Père
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.nameFather)} helperText={errorForm.nameFather}
                                         inputRef={refNameFather} title={'Nom'}/>
                        <TextFieldModify error={Boolean(errorForm.firstNameFather)}
                                         helperText={errorForm.firstNameFather}
                                         inputRef={refFirstNameFather} title={'Prenom'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.telPhoneFather)}
                                         helperText={errorForm.telPhoneFather}
                                         inputRef={refTelPhoneFather} title={'Téléphone du domicile'}/>
                        <TextFieldModify error={Boolean(errorForm.telWorkFather)}
                                         helperText={errorForm.telWorkFather}
                                         inputRef={refTelWorkFather} title={'Téléphone du travail'}/>
                        <TextFieldModify error={Boolean(errorForm.telCarryFather)}
                                         helperText={errorForm.telCarryFather}
                                         inputRef={refTelCarryFather} title={'Téléphone portable'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.emailFather)}
                                         helperText={errorForm.emailFather}
                                         inputRef={refEmailFather} title={'Email'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.workFather)} helperText={errorForm.workFather}
                                         inputRef={refWorkFather} title={'Profession'}/>
                        <TextFieldModify error={Boolean(errorForm.placeWorkFather)}
                                         helperText={errorForm.placeWorkFather}
                                         inputRef={refPlaceWorkFather} title={'Lieu de travail'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.adressFather)}
                                         helperText={errorForm.adressFather}
                                         inputRef={refAdressFather} title={'Adresse du domicile'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.regFather)} helperText={errorForm.regFather}
                                         inputRef={refRegFather} title={'Régime ( CAF , MSA , AUTRE )'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <hr/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" component="h2" className={classes.titlePart}>
                            Informations relatives à la Mère
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.nameMother)} helperText={errorForm.nameMother}
                                         inputRef={refNameMother} title={'Nom'}/>
                        <TextFieldModify error={Boolean(errorForm.firstNameMother)}
                                         helperText={errorForm.firstNameMother}
                                         inputRef={refFirstNameMother} title={'Prenom'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.telPhoneMother)}
                                         helperText={errorForm.telPhoneMother}
                                         inputRef={refTelPhoneMother} title={'Téléphone du domicile'}/>
                        <TextFieldModify error={Boolean(errorForm.telWorkMother)}
                                         helperText={errorForm.telWorkMother}
                                         inputRef={refTelWorkMother} title={'Téléphone du travail'}/>
                        <TextFieldModify error={Boolean(errorForm.telCarryMother)}
                                         helperText={errorForm.telCarryMother}
                                         inputRef={refTelCarryMother} title={'Téléphone portable'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.emailMother)}
                                         helperText={errorForm.emailMother}
                                         inputRef={refEmailMother} title={'Email'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.workMother)} helperText={errorForm.workMother}
                                         inputRef={refWorkMother} title={'Profession'}/>
                        <TextFieldModify error={Boolean(errorForm.placeWorkMother)}
                                         helperText={errorForm.placeWorkMother}
                                         inputRef={refPlaceWorkMother} title={'Lieu de travail'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.adressMother)}
                                         helperText={errorForm.adressMother}
                                         inputRef={refAdressMother} title={'Adresse du domicile'}/>
                    </Grid>
                    <Grid item xs={12} className={classes.section}>
                        <TextFieldModify error={Boolean(errorForm.regMother)} helperText={errorForm.regMother}
                                         inputRef={refRegMother} title={'Régime ( CAF , MSA , AUTRE )'}/>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.section}>
                    <hr/>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <Typography variant="h6" component="h2" className={classes.titleModify}>
                        Type de contrat souhaité
                    </Typography>
                    <RadioGroup value={valueRadioWantedContract} onChange={handleRadioWantedContract}
                                className={classes.radioGroup}>
                        <FormControlLabel value="occasionnel" control={<Radio/>} label="Occasionnel"/>
                        <FormControlLabel value="regulier" control={<Radio/>} label="Regulier"/>
                    </RadioGroup>
                    <Typography variant={"body2"} className={classes.infoThirdRadio}>
                        - Occasionnel : ce mode d’accueil est défini pour une durée non planifiée. Les enfants sont
                        inscrits
                        48h avant l’accueil, sous réserve de places disponibles.
                        <br/>
                        <br/>
                        -Régulier : les familles signent un contrat d’accueil individualisé qui indique précisément les
                        conditions d’accueil et qui permet aux parents de réserver une place sur une période définie. Il
                        est
                        révisable sous réserve de places disponibles.
                        Le contrat régulier est d’une durée de 12 mois, il est renouvelable.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <Typography variant="h6" component="h2" className={classes.titleThirdRadio}>
                        Veuillez sélectionner les jours d'accueil souhaités
                    </Typography>

                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple

                                options={allDay}
                                disableCloseOnSelect
                                value={allDayselected}
                                onChange={(event, newValue) => {

                                    let allDaySelectedWithNew = newValue

                                    let allSelectedDayInIndex = []
                                    for (let i = 0; i < allDaySelectedWithNew.length; i++) {
                                        allSelectedDayInIndex.push(dayToIndex(allDaySelectedWithNew[i]))
                                    }

                                    allSelectedDayInIndex.sort();
                                    let finalArray = []
                                    for (let i = 0; i < allDaySelectedWithNew.length; i++) {
                                        finalArray.push(allDay[allSelectedDayInIndex[i]])
                                    }

                                    setAllDaysSelected(finalArray);
                                }}
                                renderOption={(option, {selected}) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option}
                                    </React.Fragment>
                                )}
                                style={{width: 500}}
                                renderInput={(params) => (
                                    <TextField {...params} error={Boolean(errorForm.allDaySelected)}
                                               helperText={errorForm.allDaySelected} variant="outlined"/>
                                )}
                            />


                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.section}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={1} rowSpan={1}>
                                        </TableCell>
                                        {
                                            allDayselected.map((key, index) => {
                                                return <TableCell align="center" colSpan={1} rowSpan={1}
                                                                  className={classes.titleTable}>
                                                    <Typography variant="body1" component="h1"
                                                                className={classes.titleTable}>
                                                        {key}
                                                    </Typography>
                                                </TableCell>
                                            })
                                        }


                                    </TableRow>
                                    <TableRow>
                                        <TableCell rowSpan={1} align="center"> Heure d'arrivée</TableCell>


                                        {
                                            allDayselected.map((key, index) => {
                                                return <TableCell rowSpan={1}><TextFieldTimeModify
                                                    value={valueArrayTimeWanted[0][dayToIndex(key)]} i={0}
                                                    j={dayToIndex(key)}
                                                    error={Boolean(errorForm.arrayTimeWanted[0][dayToIndex(key)])}
                                                    helperText={errorForm.arrayTimeWanted[0][dayToIndex(key)]}
                                                    onChange={handleChangeValueArrayTimeWanted}/>
                                                </TableCell>
                                            })
                                        }


                                    </TableRow>

                                    <TableRow>
                                        <TableCell rowSpan={1} align="center">Heure de départ</TableCell>


                                        {
                                            allDayselected.map((key, index) => {
                                                return <TableCell rowSpan={1}><TextFieldTimeModify
                                                    value={valueArrayTimeWanted[1][dayToIndex(key)]} i={1}
                                                    error={Boolean(errorForm.arrayTimeWanted[1][dayToIndex(key)])}
                                                    helperText={errorForm.arrayTimeWanted[1][dayToIndex(key)]}
                                                    j={dayToIndex(key)}
                                                    onChange={handleChangeValueArrayTimeWanted}/>
                                                </TableCell>
                                            })
                                        }


                                    </TableRow>

                                    <TableRow>
                                        <TableCell rowSpan={1} align="center"> Total des heures</TableCell>
                                        {
                                            allDayselected.map((key, index) => {
                                                return <TableCell rowSpan={1}>{
                                                    valueArrayTimeTotal[dayToIndex(key)]
                                                }</TableCell>
                                            })
                                        }
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid item xs={12}>
                            <Typography variant="body2" className={classes.infoTimeReceiveChildren}>
                                Votre enfant sera accueili
                                : <span> {valueArrayTimeTotal.reduce((a, b) => a + b, 0)} </span> heures
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" className={classes.infoExplicationPlanning}>
                                Explications si horaires atypiques ou postés :
                            </Typography>
                            <TextField inputRef={refTextFieldInfoExplicationPlanning} maxRows={Infinity} multiline variant="outlined" className={classes.textFieldInfoExplicationPlanning}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.certificate}>
                                Veuillez cocher cette case pour certifier la conformité de vos réponses <Checkbox
                                checked={checkedCertificate} required onChange={handleChangeCertificate}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.gridButtonSend}>
                            <Button type={"submit"} variant="contained"
                                    className={classes.buttonSend}> Envoyer </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <ReCAPTCHA
                    size="invisible"
                    sitekey={process.env.REACT_APP_KEY_CAPTCHA_INVISIBLE} ref={recaptchaRef}/>

            </Grid>


        </form>
    )

}

