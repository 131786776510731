import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from "./Components/Router/router";
import * as serviceWorker from './serviceWorker';
import {createMuiTheme, responsiveFontSizes, ThemeProvider} from '@material-ui/core/styles';
import WebFont from 'webfontloader';
import {ComponentAuhtContext} from './Services/useAuth';

WebFont.load({
    google: {
        families: ['Fugaz One',  'Balsamiq Sans','Annie Use Your Telescope','Baloo Bhai']
    }
});

const theme = responsiveFontSizes(createMuiTheme({
    palette: {
        primary: {
            main: '#067f09',
        },
        secondary: {
            main: '#FFFF00',
        },
        third:{
            main:'#FF3800'
        }
    },
    font: {
        fugazOne: 'Fugaz One',
        balsamiq: 'Balsamiq Sans',
        balooBhai: 'Baloo Bhai',
        annieUseYourTelescope:'Annie Use Your Telescope'
    },
    size: {
        headerDesktop: '40vh',
        headerMiniDesktop: '40vh',
        heightBlackPart: 102,
        heightHeaderPhone: 90,
    },
    speed: {
        animation: 1000,
    },
}));



if (!window.location.host.startsWith("www") && window.location.host!== "localhost:3000"){
    window.location = window.location.protocol + "//" + "www." + window.location.host + window.location.pathname;
}

ReactDOM.render(
    <ComponentAuhtContext>
        <ThemeProvider theme={theme}>
            <Router/>
        </ThemeProvider>,
    </ComponentAuhtContext>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
