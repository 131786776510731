import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    container: {
        zIndex: '2',
        position: 'relative',
        width: '100%',

    },
    link: {
        fontFamily: theme.font.balsamiq + " !important",
        cursor:'pointer',
    },
    containerLink: {
        width: 'max-content !important',
        float: 'right',
        color: 'white',
    },
    separator: {
        marginLeft: '5px !important',
        marginRight: '5px  !important',
    },
    firstHeader: {
        backgroundColor: '#000000B3',
        paddingTop: '15px',
        paddingLeft: '15px',
        paddingBottom: '15px',
        paddingRight: '15px',
    },
    title: {
        color: theme.palette.primary.main,
        fontFamily: theme.font.fugazOne + " !important",
    },
    containerLinkIcons: {
        display: 'flex',
        alignItems: 'center',
        height:'100%',
        color:'white',
    },
    underTitle: {
        color: "white",
        fontFamily: theme.font.balsamiq + " !important",
    },
    icons:{
        cursor:'pointer',
    },
}));

export default useStyles;