import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import useStyles from "./style";


export default function AddUser(props) {

    const classes = useStyles();

    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");


    const [lastName,setLastName] = React.useState();
    const [firstName,setFirsName] = React.useState();
    const [email,setEmail] = React.useState();


    const handleSubmit = async (e) => {
        setError('')
        setSuccess('')
        e.preventDefault();
        const regexMail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);


        if(lastName === ""){
            setError('Veuillez préciser un nom de famille')
        }
        else if(firstName === ""){
            setError('Veuillez préciser un prénom')
        }
        else if(email === ""  ||  !regexMail.test(email)){
            setError('Veuillez préciser un email valide')
        }
        else {
            props.setSpinnerValue(1);
            let submitBody = {
                firstName:firstName,
                lastName:lastName,
                email:email,
            };


           fetch(process.env.REACT_APP_API_URL + "/api/user/signUp", {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
                credentials: "include",
                body:JSON.stringify(submitBody)
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return Promise.reject(response);
                    }
                })
                .then((response) => {

                    setSuccess("L'utilisateur a bien été crée avec le mot de passe : " + response.password);
                    setFirsName('')
                    setLastName('')
                    setEmail('')
                    props.setSpinnerValue(0)
                })
                .catch(function (error) {
                    props.setSpinnerValue(0)

                    error.json().then((body) => {
                        if(body.message ==="Email already use" ){
                            setError("Email déjà utilisée")
                        }
                        else {
                            setError("Erreur dans l'ajout de l'utilisateur")
                        }


                    });

                });
        }



    };


    return (
        <div className={classes.root}>


            <Typography variant="h5" component="h1" className={classes.title}>
                Ajouter un utilisateur
            </Typography>

            <Grid container justify="flex-start" alignItems="center" className={classes.gridContainer} spacing={1}>
                <Grid item  xs={12} sm={2}>
                    <div className={classes.containerNameInput}>
                        <Typography variant="body1" component="h1" className={classes.nameInput}>
                            Nom :
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField variant="outlined" label="Nécessaire *" value={lastName}  className={classes.textField}
                              onChange={(e)=>setLastName(e.target.value)}/>
                </Grid>

                <Grid item xs={12} sm={2}>
                    <div className={classes.containerNameInput}>
                        <Typography variant="body1" component="h1" className={classes.nameInput}>
                            Prénom :
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField variant="outlined" label="Nécessaire *" value={firstName}  className={classes.textField}
                               onChange={(e)=>setFirsName(e.target.value)}/>
                </Grid>

                <Grid item xs={12} sm={2}>
                    <div className={classes.containerNameInput}>
                        <Typography variant="body1" component="h1"  className={classes.nameInput}>
                            Email :
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField variant="outlined" label="Nécessaire *" value={email}  className={classes.textField}
                               onChange={(e)=>setEmail(e.target.value)}/>
                </Grid>


                <Grid item xs={12} className={classes.gridButtonSend}>
                    <Button onClick={handleSubmit} type={"submit"} variant="contained"
                            className={classes.buttonSend}> Envoyer </Button>
                </Grid>
                <Grid item xs={12} className={classes.gridButtonSend}>
                    {
                        error && <Alert severity="error">{error}</Alert>
                    }
                    {
                        success && <Alert severity="success">{success}</Alert>
                    }
                </Grid>

            </Grid>
        </div>
    )
}