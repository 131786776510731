import React from "react";
import useStyles from "./style";
import {ContextAuth} from "../../Services/useAuth";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Button from "@material-ui/core/Button";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function PopperConnection(props) {

    const classes = useStyles();

    const refMail = React.createRef();
    const refPass = React.createRef();

    const [error, setError] = React.useState("");

    const auth = ContextAuth();

    const handleClickAway = () => {
        props.setOpen(false)
    };


    const connect = (email, password) => {
        let submitBody = {
            email: email,
            password: password,
        };

        return auth.signIn(submitBody)
    };

    const signIn = async (e) => {
        e.preventDefault();
        const valueMail = refMail.current.value;
        const valuePass = refPass.current.value;

        const regexMail = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
        if (regexMail.test(valueMail)) {
            await connect(valueMail, valuePass).then((response) => {

                if (response === "ok") {

                    props.setOpen(false)
                } else {
                    setError("Email ou mot de passe invalide")
                }
            }).catch(()=>{
                setError("Email ou mot de passe invalide")
            });
        } else {
            setError("Email invalide")
        }
    };

    return (
        <div>


            {auth.state.isLoading ? "" :
                <Popper open={props.open} anchorEl={props.anchorEl} transition
                        className={classes.popperConnection}>
                    {({TransitionProps}) => (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.container}>
                                    <form onSubmit={signIn}>


                                        <Grid container justify={"center"}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5" component="h1" className={classes.title}>
                                                    Se connecter
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField variant="outlined" required label="Email"
                                                           className={classes.textFieldFirst} inputRef={refMail}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField variant="outlined" required label="Mot de passe"
                                                           type={"password"}
                                                           className={classes.textFieldSecond} inputRef={refPass}/>
                                            </Grid>
                                            <Grid item xs={12}>

                                                <Button type={"submit"} variant="contained"
                                                        className={classes.buttonSend} > Se
                                                    connecter </Button>


                                            </Grid>
                                            {
                                                error &&
                                                <Grid item xs={12} className={classes.containerError}>
                                                    <Alert severity="error">{error}</Alert>
                                                </Grid>
                                            }

                                        </Grid>
                                    </form>
                                </Paper>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            }
        </div>
    )
}