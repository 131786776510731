import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        height: '100vh',
        backgroundColor:'#00000078',
        width:'100%',
        zIndex:'5000',
    },
    link:{
        width:'max-content',
        marginTop:'35px',
        color: "white",
        cursor:'pointer',
        fontFamily: theme.font.annieUseYourTelescope + " !important",
    },
    containerGrid:{

        backgroundColor:'#000000CC',
        width:'max-content',
        height: '100%',
        paddingRight:'50px',
        paddingLeft:'50px',
    },

    underline: {
        backgroundImage: 'linear-gradient('+theme.palette.secondary.main+','+theme.palette.secondary.main+') !important',
        backgroundRepeat: ' no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: '50px 3px',
        '&:hover':{
            animation: "1.5s $slidein infinite",
        },
    },
}));

export default useStyles;