import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DehazeIcon from "@material-ui/icons/Dehaze";
import useStyles from "./style";
import NavPhone from "../../NavPhone/navPhone";
import CloseIcon from '@material-ui/icons/Close';
import Grow from "@material-ui/core/Grow";
import {useTheme} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import PopperConnection from "../../PopperConnection/popperConnection";


export default function HeaderPhone(props) {
    const classes = useStyles();
    const theme = useTheme()



    const [anchorElConnection, setAnchorElConnection] = React.useState(null);
    const [openConnection, setOpenConnection] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    return (
        <div className={classes.root}>
            <PopperConnection anchorEl={anchorElConnection} open={openConnection} setOpen={setOpenConnection}/>
            <Grid container justify="center" alignItems="center" className={classes.phoneContainerTitle}>
                <Grid item className={classes.containerPhoneTitle} xs={12}>
                    <Typography variant="h5" component="h1" className={classes.phoneTitle}>
                        Les P'tits Pieds Dans Les Prés
                    </Typography>
                </Grid>


                <Grid item xs={1}>
                    {
                        open ? <CloseIcon onClick={() => setOpen(false)} className={classes.button}/> :
                            <DehazeIcon onClick={() => setOpen(true)} className={classes.button}/>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={classes.containerLink}>
                        <Grid item>
                            {
                                props.auth.state.isAuthenticated ?
                                    <Typography variant="h6" component="h3" className={classes.link}
                                                onClick={(e) => {
                                                    props.auth.signOut()
                                                }}>

                                        Se déconnecter
                                    </Typography> :
                                    <Typography variant="h6" component="h3" className={classes.link}
                                                onClick={(e) => {
                                                    setAnchorElConnection(e.currentTarget)
                                                    setOpenConnection(!openConnection)
                                                }}>

                                        Se connecter
                                    </Typography>
                            }
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" component="h3" className={classes.separator}>
                                |
                            </Typography>
                        </Grid>
                        <Grid item >
                            <a href={"https://www.facebook.com/Les-Ptits-Pieds-dans-les-Pr%C3%A9s-253155721872463"} className={classes.containerLinkIcons}>
                                <FacebookIcon className={classes.icons} />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>


            <Grow in={open}
                   timeout={theme.speed.animation}>
                <div>
                    < NavPhone auth={props.auth}/>
                </div>
            </Grow>




        </div>
    )
}