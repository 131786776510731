import React from "react";
import Header from "../Header/header";
import AcceptCGU from "../AcceptCGU/acceptCGU";
import useStyles from "./style"
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {ContextAuth} from "../../Services/useAuth";

export default  function Error404(){

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });


    React.useEffect(() => {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                });
            }

            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        }
    ,[]);

    const auth = ContextAuth();
    const classes = useStyles();

    return(
        <div>
            {
                auth.state.isLoading ? "" :
                    <div>
                        <AcceptCGU/>
                        <Header width={dimensions.width}  auth={auth}/>
                        <Container>
                            <Typography variant="h4" component="h1" className={classes.title}>
                                Erreur 404
                            </Typography>
                            <hr className={classes.hrTitle}/>
                            <Typography variant="h4" component="h1" className={classes.text}> La page demandée n'existe
                                pas </Typography>
                        </Container>

                    </div>
            }
        </div>

    )
}