import React from "react";
import Typography from "@material-ui/core/Typography";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from "./style";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from '@material-ui/icons/Delete';
import ModalDeletePhotos from "./ModalDeletePhotos/deletePhotos";
import Alert from "@material-ui/lab/Alert";
import ModalDeleteTheme from "./ModalDeleteTheme/deleteTheme";

export default function DeletePhotosAndTheme(props) {

    const classes = useStyles();

    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const [theme, setTheme] = React.useState([])
    const [actualDeletePhotos,setActualDeletePhotos] = React.useState("")
    const [actualDeleteTheme,setActualDeleteTheme] = React.useState({})

    const [openModalDeleteTheme, setOpenModalDeleteTheme] = React.useState(false);

    const [openModalDeletePhotos, setOpenModalDeletePhotos] = React.useState(false);

    const getTheme = () => {
        props.setSpinnerValue(1)
        fetch(process.env.REACT_APP_API_URL + "/api/theme/getTheme", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then((response) => {
                setTheme(response)
                props.setSpinnerValue(0)
            })
            .catch(function (error) {
                props.setSpinnerValue(0)
            });

    }


    React.useEffect(() => {
        getTheme()
    }, []);



    return (
        <div className={classes.root}>
            <ModalDeletePhotos  getTheme={getTheme} setSpinnerValue={props.setSpinnerValue} setError={setError} setSuccess={setSuccess}  openModalDeletePhotos={openModalDeletePhotos} setOpenModalDeletePhotos={setOpenModalDeletePhotos} actualDeletePhotos={actualDeletePhotos}>

            </ModalDeletePhotos>
            <ModalDeleteTheme  getTheme={getTheme} setSpinnerValue={props.setSpinnerValue} setError={setError} setSuccess={setSuccess}  openModalDeleteTheme={openModalDeleteTheme} setOpenModalDeleteTheme={setOpenModalDeleteTheme} actualDeleteTheme={actualDeleteTheme}/>

            <Typography variant="h5" component="h1" className={classes.title}>
                Supprimer des photos
            </Typography>
            <Grid container justify={"center"}>
                <Grid item xs={12} className={classes.gridButtonSend}>
                    {
                        error && <Alert severity="error">{error}</Alert>
                    }
                    {
                        success && <Alert severity="success">{success}</Alert>
                    }
                </Grid>
                {
                    theme.map((key, index) => (
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                            >
                                <Typography className={classes.heading}>{key.name}</Typography>
                                <DeleteIcon className={classes.deleteIcon} onClick={()=>{
                                    setActualDeleteTheme(key)
                                    setOpenModalDeleteTheme(true)
                                }}/>

                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>

                                    {key.allPhotos.map((key2, index2) => (
                                        <Grid item xs={12} lg={6}>
                                            <img alt={"Chargement"}
                                                 className={classes.img}
                                                 src={process.env.REACT_APP_API_URL + "/api/photos/getPhotoById?id=" + key2}
                                                 onClick={()=>{
                                                     setActualDeletePhotos(key2)
                                                     setOpenModalDeletePhotos(true)
                                                 }}
                                            />
                                        </Grid>

                                    ))
                                    }
                                </Grid>

                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </Grid>

        </div>
    )
}