import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    rootTable:{
        overflow: 'scroll',
    },
    title: {
        marginTop: '50px',
        textAlign: 'center',
        fontFamily: theme.font.balsamiq + " !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.size.heightHeaderPhone,
        },
    },
    hrTitle: {
        width: '10%',
        height: '2px',
        backgroundColor: theme.palette.secondary.main,
        border: 'none',
        marginBottom: '25px',
    },
    table:{
        width:'80%',
        margin:'auto',
        textAlign:'center',
        maxWidth:'1100px',
        minWidth:'550px',
        [theme.breakpoints.down("sm")]: {
            width:'99%',
        },
        marginBottom:'25px',
    },
    greyBackground:{
        backgroundColor:'rgba(0, 0, 0, 0.04)',
    },
    titleTable:{
        fontFamily: theme.font.balsamiq + " !important",

    },
}));

export default useStyles;