import React from "react";
import useStyles from "./style";
import Header from "../Header/header";
import AcceptCGU from "../AcceptCGU/acceptCGU";
import Footer from "../Footer/footer";
import {ContextAuth} from "../../Services/useAuth";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Person from '@material-ui/icons/Person';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import Typography from "@material-ui/core/Typography";
import AddPhotos from "./AddPhotos/addPhotos";
import Spinner from "../Spinner/spinner";
import {useTheme} from "@material-ui/core/styles";
import AddUser from "./AddUser/addUser";
import ManageUser from "./ManageUser/manageUser";
import DeletePhotosAndTheme from "./DeletePhotosTheme/deletePhotosAndTheme"
import CreateCategory from "./CreateCategory/createCategory";
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';




export default function DashboardAdmin() {

    const classes = useStyles();
    const theme = useTheme();

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });


    React.useEffect(() => {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                });
            }

            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        }
        , []);

    const [actualView, setActualView] = React.useState(0)
    const [spinnerValue, setSpinnerValue] = React.useState(0)

    const auth = ContextAuth();
    return (
        <div>
            {
                auth.state.isLoading ? "" :
                    <div>
                        <Spinner loading={spinnerValue} color={theme.palette.primary.main}/>
                        <AcceptCGU/>
                        <Header width={dimensions.width} auth={auth}/>
                        <Container>
                            <Typography variant="h4" component="h1" className={classes.title}>
                                Admin
                            </Typography>
                            <hr className={classes.hrTitle}/>
                            <Grid container className={classes.containerGrid}>
                                <Grid item xs={12} md={3}>
                                    <List component="nav" className={classes.nav}>
                                        <ListItem button onClick={() => setActualView(1)}>
                                            <ListItemIcon>
                                                <PersonAddIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Ajouter un utilisateur"/>
                                        </ListItem>
                                        <ListItem button onClick={() => setActualView(2)}>
                                            <ListItemIcon>
                                                <Person/>
                                            </ListItemIcon>
                                            <ListItemText primary="Gérer les utilisateurs"/>
                                        </ListItem>
                                        <ListItem button onClick={() => setActualView(0)}>
                                            <ListItemIcon>
                                                <AddToPhotosIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Ajouter des photos"/>
                                        </ListItem>
                                        <ListItem button onClick={() => setActualView(3)}>
                                            <ListItemIcon>
                                                <DeleteIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Supprimer des photos et thèmes"/>
                                        </ListItem>
                                        <ListItem button onClick={() => setActualView(4)}>
                                            <ListItemIcon>
                                                <FolderIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Créer un thème"/>
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    {
                                        actualView === 0 ?
                                            <AddPhotos setSpinnerValue={setSpinnerValue}/> :
                                            actualView === 1 ?
                                                <AddUser setSpinnerValue={setSpinnerValue}/> :
                                                actualView === 2 ?
                                                    <ManageUser setSpinnerValue={setSpinnerValue}/> :
                                                    actualView === 3 ?
                                                        <DeletePhotosAndTheme setSpinnerValue={setSpinnerValue}/> :
                                                        actualView === 4 ?
                                                            <CreateCategory setSpinnerValue={setSpinnerValue}/> : ""
                                    }
                                </Grid>


                            </Grid>
                        </Container>
                        <Footer/>
                    </div>
            }
        </div>
    )
}