import React from "react";
import {Typography} from "@material-ui/core";
import useStyles from "./style";
import {useHistory} from "react-router";



export default function Footer() {

    const classes = useStyles();
    const history = useHistory()

    return(
        <div className={classes.root}>
            <hr className={classes.hr}/>

            <Typography   component="h3">
                © Les p’tits pieds dans les prés 2020 | <span className={classes.button} onClick={()=>history.push('/CGU')}> CGU</span>  | <span className={classes.button} onClick={()=>history.push('/privacyPolicy')}>Politique de confidentialité</span>
            </Typography>

        </div>



    )
}