import React from "react";
import useStyles from "./style";
import Header from "../Header/header";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Footer from "../Footer/footer";
import AcceptCGU from "../AcceptCGU/acceptCGU";
import {ContextAuth} from "../../Services/useAuth";

export default function CGU() {

    const classes = useStyles();
    const auth = ContextAuth();

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    React.useEffect(() => {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                });
            }

            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        }
    ,[]);

    return (
        <div>
            {
                auth.state.isLoading ? "" :
                    <div>
                        <AcceptCGU/>
                        <Header width={dimensions.width} auth={auth}/>
                        <Grid container justify={"center"}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h3" className={classes.title}>
                                    Conditions générales d'utilisation
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 1 : Objet
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Les présentes « conditions générales d'utilisation » ont pour objet l'encadrement
                                    juridique de l’utilisation du site www.les-ptits-pieds-dans-les-prés.fr et de ses
                                    services.
                                    <br/>
                                    Ce contrat est conclu entre :
                                    <br/>
                                    Le gérant du site internet, ci-après désigné « l’Éditeur »,
                                    <br/>
                                    Toute personne physique ou morale souhaitant accéder au site et à ses services,
                                    ci-après appelé « l’Utilisateur ».
                                    <br/>
                                    Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur,
                                    et son accès au site vaut acceptation de ces conditions.
                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 2 : Mentions légales
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Le site www.les-ptits-pieds-dans-les-prés.fr est édité par DESSE Flavien
                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 3: accès aux services
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    L’Utilisateur du site www.les-ptits-pieds-dans-les-prés.fr a accès aux services
                                    suivants :
                                    <br/>
                                    . Formulaire de contact
                                    <br/>
                                    . Formulaire d’inscription
                                    <br/>
                                    . Informations relatives à l’entreprise Les P’tits Pieds Dans Les Près
                                    <br/>
                                    <br/>
                                    Tout Utilisateur ayant accès à internet peut accéder gratuitement et depuis
                                    n’importe où au site. Les frais supportés par l’Utilisateur pour y accéder
                                    (connexion internet, matériel informatique, etc.) ne sont pas à la charge de
                                    l’Éditeur.
                                    <br/>
                                    Les services suivants ne sont pas accessibles pour l’Utilisateur que s’il est membre
                                    du site (c’est-à-dire qu’il est identifié à l’aide de ses identifiants de connexion)
                                    :
                                    <br/>
                                    • Accès au photo et vidéo relatives à la crèche
                                    <br/>
                                    <br/>
                                    Le site et ses différents services peuvent être interrompus ou suspendus par
                                    l’Éditeur, notamment à l’occasion d’une maintenance, sans obligation de préavis ou
                                    de justification.

                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 4: Responsabilité de l’Utilisateur
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    L'Utilisateur est responsable des risques liés à l’utilisation de son identifiant de
                                    connexion et de son mot de passe.
                                    <br/>
                                    Le mot de passe de l’Utilisateur doit rester secret. En cas de divulgation de mot de
                                    passe, l’Éditeur décline toute responsabilité.
                                    <br/>
                                    L’Utilisateur assume l’entière responsabilité de l’utilisation qu’il fait des
                                    informations et contenus présents sur le site www.les-ptits-pieds-dans-les-pres.fr.
                                    <br/>
                                    Tout usage du service par l'Utilisateur ayant directement ou indirectement pour
                                    conséquence des dommages doit faire l'objet d'une indemnisation au profit du site.


                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 5 : Responsabilité de l’Éditeur
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Tout dysfonctionnement du serveur ou du réseau ne peut engager la responsabilité de
                                    l’Éditeur.
                                    <br/>

                                    De même, la responsabilité du site ne peut être engagée en cas de force majeure ou
                                    du fait imprévisible et insurmontable d'un tiers.
                                    <br/>

                                    Le site www.les-ptits-pieds-dans-les-prés.fr s'engage à mettre en œuvre tous les
                                    moyens nécessaires pour garantir la sécurité et la confidentialité des données.
                                    Toutefois, il n’apporte pas une garantie de sécurité totale.
                                    <br/>

                                    L’Éditeur se réserve la faculté d’une non-garantie de la fiabilité des sources, bien
                                    que les informations diffusées sur le site soient réputées fiables.


                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 6: Propriété intellectuelle
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Les contenus du site www.les-ptits-pieds-dans-les-prés.fr (logos, textes, éléments
                                    graphiques, vidéos, etc.) sont protégés par le droit d’auteur, en vertu du Code de
                                    la propriété intellectuelle.
                                    <br/>

                                    L’Utilisateur devra obtenir l’autorisation de l’éditeur du site avant toute
                                    reproduction, copie ou publication de ces différents contenus.
                                    <br/>

                                    Ces derniers peuvent être utilisés par les utilisateurs à des fins privées ; tout
                                    usage commercial est interdit.
                                    <br/>

                                    L’Utilisateur est entièrement responsable de tout contenu qu’il met en ligne et il
                                    s’engage à ne pas porter atteinte à un tiers.
                                    <br/>

                                    L’Éditeur du site se réserve le droit de modérer ou de supprimer librement et à tout
                                    moment les contenus mis en ligne par les utilisateurs, et ce sans justification.


                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 7 : Données personnelles
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    L’Utilisateur doit obligatoirement fournir des informations personnelles pour
                                    procéder à son inscription sur le site.
                                    <br/>

                                    L’adresse électronique (e-mail) de l’utilisateur pourra notamment être utilisée par
                                    www.les-ptits-pieds-dans-les-prés.fr pour la communication d’informations diverses
                                    et la gestion du compte.
                                    <br/>

                                    Les P’tits Pieds Dans Les Près garantie le respect de la vie privée de
                                    l’utilisateur, conformément à la loi n°78-17 du 6 janvier 1978 relative à
                                    l'informatique, aux fichiers et aux libertés.
                                    <br/>


                                    En vertu des articles 39 et 40 de la loi en date du 6 janvier 1978, l'Utilisateur
                                    dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses
                                    données personnelles. L'Utilisateur exerce ce droit via :
                                    <br/>

                                    • Son espace personnel sur le site ;
                                    <br/>

                                    • Un formulaire de contact ;
                                    <br/>

                                    • Par mail à weirding.code@gmail.com ;
                                    <br/>


                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 8 : Liens hypertextes et cookies
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Les domaines vers lesquels mènent les liens hypertextes présents sur le site
                                    n’engagent pas la responsabilité de l’Éditeur de Les P’tits Pieds Dans Les Près, qui
                                    n’a pas de contrôle sur ces liens.
                                    <br/>

                                    Il est possible pour un tiers de créer un lien vers une page du site
                                    www.les-ptits-pieds-dans-les-prés.fr sans autorisation expresse de l’éditeur.
                                    <br/>

                                    La navigation sur le site www.les-ptits-pieds-dans-les-prés.fr est susceptible de
                                    provoquer l'installation de cookie(s) sur l'ordinateur de l'utilisateur. Un cookie
                                    est un fichier de petite taille, qui ne permet pas l'identification de
                                    l'utilisateur, mais qui enregistre des informations relatives à la navigation d'un
                                    ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation
                                    ultérieure sur le site, et ont également vocation à permettre diverses mesures de
                                    fréquentation.
                                    <br/>
                                    Le refus d'installation d'un cookie peut entraîner l'impossibilité d'accéder à
                                    certains services. L'utilisateur peut toutefois configurer son ordinateur de la
                                    manière suivante, pour refuser l'installation des cookies :
                                    <br/>
                                    Sous Internet Explorer : onglet outil / options internet. Cliquez sur
                                    Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.
                                    <br/>
                                    Sous Netscape : onglet édition / préférences. Cliquez sur Avancées et choisissez
                                    Désactiver les cookies. Validez sur Ok.
                                    <br/>
                                    Sous Chrome : En haut à droite, cliquez sur Plus puis sur Paramètres. Sous
                                    "Confidentialité et sécurité", cliquez sur Cookies et autres données de site.
                                    Cliquez sur Afficher l'ensemble des cookies et données de sites Tout supprimer.Et
                                    enfin Confirmez la suppression en cliquant sur Tout effacer.
                                    <br/>
                                    Cookies de sessions présents sur ce site :
                                    <br/>


                                    <br/>

                                    Autres cookies :
                                    <br/>


                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 9 : Évolution des conditions générales d’utilisation
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Le site www.les-ptits-pieds-dans-les-prés.fr se réserve le droit de modifier les
                                    clauses de ces conditions générales d’utilisation à tout moment et sans
                                    justification.


                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 10 : Durée du contrat
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    La durée du présent contrat est indéterminée. Le contrat produit ses effets à
                                    l'égard de l'Utilisateur à compter du début de l’utilisation du service.

                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    ARTICLE 11 : Droit applicable et juridiction compétente
                                </Typography>
                            </Grid>
                            <Grid item md={7} xs={11}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Le présent contrat dépend de la législation française.
                                    <br/>
                                    En cas de litige non résolu à l’amiable entre l’Utilisateur et l’Éditeur, les
                                    tribunaux de Lille sont compétents pour régler le contentieux.

                                </Typography>
                                <br/>
                            </Grid>
                        </Grid>
                        <Footer/>
                    </div>
            }
        </div>
    )
}