import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    nav: {
        boxShadow: '2px 0px 1px grey',
        padding: '0px',
        height: '100%',
        display: 'flex',
        flexDirection: "column",

    },
    containerGrid: {
        marginTop: '25px',
        marginBottom: '25px',
        border: 'solid grey 1px',
    },
    title: {
        marginTop: '50px',
        textAlign: 'center',
        fontFamily: theme.font.balsamiq + " !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.size.heightHeaderPhone,
        },
    },
    hrTitle: {
        width: '10%',
        height: '2px',
        backgroundColor: theme.palette.secondary.main,
        border: 'none',
        marginBottom: '25px',
    },
}));

export default useStyles;