import React from "react";
import useStyles from "./style";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import Files from 'react-files'
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AddPhotos(props) {
    const classes = useStyles();
    const [allFile, setAllFile] = React.useState([]);
    const [error, setError] = React.useState("")
    const [success, setSuccess] = React.useState("")
    const [theme, setTheme] = React.useState([])
    const [actualTheme, setActualTheme] = React.useState()


    const refMessage = React.createRef();

    const addImage = async (e) => {
        for (let i = 0; i < e.length; i++) {
            await setAllFile(allFile => [...allFile, e[i]]);
        }
        e.length = 0;
    };


    const handleDeleteFile = (index) => {
        let temp = allFile.slice();
        temp.splice(index, 1);
        setAllFile(temp)
    };

    const getTheme = () => {
        fetch(process.env.REACT_APP_API_URL + "/api/theme/getTheme", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then((response) => {
                setTheme(response)
                props.setSpinnerValue(0)
            })
            .catch(function (error) {
                props.setSpinnerValue(0)
            });

    }

    React.useEffect(() => {
        getTheme()
    }, [])


    const handleSubmit = (e) => {
        setError('')
        setSuccess('')
        e.preventDefault();
        let size = 0
        allFile.forEach(temp => size += temp.size)
        if (allFile.length <= 0) {
            setError("Veuillez ajouter au moins une photo")
        } else if (actualTheme === undefined || actualTheme === null) {
            setError("Veuillez ajouter un thème")
        } else if (size > 20000000) {
            setError("Le poid des fichiers excéde 20Mo")
        } else {
            props.setSpinnerValue(1)
            const data = new FormData();
            for (const elem of allFile) {
                data.append('photos', elem);
            }
            data.append('theme', JSON.stringify(actualTheme))

            fetch(process.env.REACT_APP_API_URL + "/api/photos/createPhotos", {
                method: "POST",
                credentials: "include",
                body: data,
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return Promise.reject(response);
                    }
                })
                .then(() => {
                    props.setSpinnerValue(0);
                    setSuccess("Les photos ont été ajoutées ")

                })
                .catch(function (error) {

                    props.setSpinnerValue(0)
                    error.json().then((body) => {
                        if (body.message === "") {

                        } else {

                        }
                        setError("Erreur dans l'envoi des fichiers")

                    });
                    return error;
                });
        }

    };

    return (
        <div className={classes.root}>


            <Typography variant="h5" component="h1" className={classes.title}>
                Ajouter des photos
            </Typography>
            <Grid item xs={12} className={classes.gridButtonSend}>
                {
                    error && <Alert severity="error">{error}</Alert>
                }
                {
                    success && <Alert severity="success">{success}</Alert>
                }
            </Grid>
            <Grid container justify="flex-start" alignItems="center" className={classes.gridContainer} spacing={1}>

                <Grid item xs={12} sm={2}>
                    <div className={classes.containerNameInput}>
                        <Typography variant="body1" component="h1" className={classes.nameInput}>
                            Thème :
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Autocomplete
                        onChange={(e, value) => setActualTheme(value)}
                        options={theme}
                        getOptionLabel={(option) => option.name}
                        className={classes.textField}
                        renderInput={(params) => <TextField {...params} label="Veuillez sélectionner un thème"
                                                            variant="outlined"/>}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <div className={classes.containerNameInput}>
                        <Typography variant="body1" component="h1" className={classes.nameInput}>
                            Photos :
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <div className={classes.containerFileUpload}>
                        <Files type={'file'} onChange={(e) => addImage(e)} multiple className={classes.addFile}
                               accepts={['image/*']}>
                            Cliquer ici pour ajouter des photos
                        </Files>

                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {allFile.map((e, index) =>
                        <div className={classes.containerOfEachImgPreview} key={index}>
                            <img src={e.preview.url} alt={'image ' + index} key={index} className={classes.imgPreview}/>
                            <DeleteIcon color={"primary"} onClick={() => handleDeleteFile(index)}/>
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} className={classes.gridButtonSend}>
                    <Button onClick={handleSubmit} type={"submit"} variant="contained"
                            className={classes.buttonSend}> Envoyer </Button>
                </Grid>


            </Grid>
        </div>
    )
}