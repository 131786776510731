import React from "react";
import useStyles from "./style";
import Header from "../Header/header";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../Footer/footer";
import GoogleMapReact from 'google-map-react';
import AcceptCGU from "../AcceptCGU/acceptCGU";
import {ContextAuth} from "../../Services/useAuth";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Spinner from "../Spinner/spinner";
import useTheme from "@material-ui/core/styles/useTheme";

export default function Contact() {

    const classes = useStyles();
    const auth = ContextAuth();
    const theme = useTheme()
    const recaptchaRef = React.createRef();

    const refEmail = React.createRef();
    const refFirstName = React.createRef();
    const refMessage = React.createRef();
    const refName = React.createRef();
    const refObject = React.createRef();

    const [messageSnackbar, setMessageSnackBar] = React.useState("")
    const [severity, setSeverity] = React.useState("")
    const [openSnackbar, setOpenSnackbar] = React.useState(false)

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });


    const [scaleRECAPTCHA, setScaleRECAPTCHA] = React.useState(window.innerWidth < 500 ? window.innerWidth / 600 : 1);


    const [error, setError] = React.useState({
        email: '',
        firstName: '',
        message: '',
        name: '',
        object: '',
    })
    const [spinnerValue, setSpinnerValue] = React.useState(0)
    const onSubmit = (e) => {
        e.preventDefault();


        const recaptchaValue = recaptchaRef.current.getValue();
        const emailValue = refEmail.current.value;
        const firstNameValue = refFirstName.current.value;
        const nameValue = refName.current.value;
        const objectValue = refObject.current.value;
        const messageValue = refMessage.current.value;


        const regexMail = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");


        if (nameValue === "") {
            setError(temp => {
                return {
                    email: '',
                    firstName: '',
                    message: '',
                    name: 'Veuillez renseigner ce champ',
                    object: '',
                    captcha: '',
                }

            });
            return;
        }
        if (emailValue === "") {
            setError(temp => {
                return {
                    email: 'Veuillez renseigner ce champ',
                    firstName: '',
                    message: '',
                    name: '',
                    object: '',
                    captcha: '',
                }

            });
            return;
        }
        if (!regexMail.test(emailValue)) {
            setError(temp => {
                return {
                    email: 'Veuillez renseigner une email valide',
                    firstName: '',
                    message: '',
                    name: '',
                    object: '',
                    captcha: '',
                }

            });
            return;
        }
        if (objectValue === "") {
            setError(temp => {
                return {
                    email: '',
                    captcha: '',
                    firstName: '',
                    message: '',
                    name: '',
                    object: 'Veuillez renseigner ce champ',
                }

            });
            return;
        }
        if (messageValue === "") {
            setError(temp => {
                return {
                    email: '',
                    firstName: '',
                    message: 'Veuillez renseigner ce champ',
                    name: '',
                    captcha: '',
                    object: '',
                }

            });
            return;
        }
        if (recaptchaValue === "") {
            setError(temp => {
                return {
                    email: '',
                    firstName: '',
                    message: '',
                    name: '',
                    object: '',
                    captcha: 'Veuillez valider le Captcha',
                }

            });

        }

        setSpinnerValue(1)
        setError({
            email: '',
            firstName: '',
            message: '',
            name: '',
            object: '',
        })


        fetch(process.env.REACT_APP_API_URL + "/api/mail/contact", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                email: emailValue,
                firstName: firstNameValue,
                message: messageValue,
                name: nameValue,
                object: objectValue,
                captcha: recaptchaValue,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response);
                }
            })
            .then(() => {
                setSpinnerValue(0);
                setMessageSnackBar('Email envoyé')
                setSeverity("success")
                setOpenSnackbar(true);


            })
            .catch(function (error) {

                setSpinnerValue(0)
                error.json().then((body) => {
                    if (body.message === "") {

                    }
                    setMessageSnackBar('Erreur')
                    setOpenSnackbar(true);
                    setSeverity("error")

                });
                return error;
            });


    };


    function handleResize() {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
        });

        if (window.innerWidth < 500) {
            setScaleRECAPTCHA(window.innerWidth / 600)

        } else {
            setScaleRECAPTCHA(1)
        }

    }

    React.useEffect(() => {
            handleResize();
        }
        , []);

    React.useEffect(() => {
            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        }
        , []);

    const handleCloseSnackbar = async (e, reason) => {
        if (reason !== 'clickaway') {
            setOpenSnackbar(false)
        }
    };


    window.recaptchaOptions = {
        useRecaptchaNet: true,
    };


    return (
        <div>
            <Spinner loading={spinnerValue || auth.state.isLoading} color={theme.palette.primary.main}/>
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    autoHideDuration={6000}
                    open={openSnackbar}
                    onClose={handleCloseSnackbar}>

                    <Alert severity={severity} onClose={handleCloseSnackbar}>
                        {messageSnackbar}
                    </Alert>

                </Snackbar>
                <AcceptCGU/>
                <Header width={dimensions.width} auth={auth}/>

                    <Typography variant="h4" component="h1" className={classes.title}>
                        Nous Contacter
                    </Typography>
                    <hr className={classes.hrTitle}/>
                <Container className={classes.container}>
                    <form onSubmit={onSubmit} className={classes.form}>
                        <Grid container justify="center" alignItems="center" className={classes.gridContainer}>
                            <Grid item xs={4} md={5}>
                                <div className={classes.containerNameInput}>
                                    <Typography variant="body1" component="h1" className={classes.nameInput}>
                                        Nom *
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <TextField error={Boolean(error.name)} helperText={error.name} variant="outlined"
                                           label="Nécessaire *" className={classes.textField}
                                           inputRef={refName}/>
                            </Grid>

                            <Grid item xs={4} md={5}>
                                <div className={classes.containerNameInput}>
                                    <Typography variant="body1" component="h1" className={classes.nameInput}>
                                        Prénom
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <TextField
                                           variant="outlined" className={classes.textField}
                                           inputRef={refFirstName}/>
                            </Grid>

                            <Grid item xs={4} md={5}>
                                <div className={classes.containerNameInput}>
                                    <Typography variant="body1" component="h1" className={classes.nameInput}>
                                        Email: *
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <TextField error={Boolean(error.email)} helperText={error.email} variant="outlined"
                                           label="Nécessaire *" className={classes.textField}
                                           inputRef={refEmail}/>
                            </Grid>

                            <Grid item xs={4} md={5}>
                                <div className={classes.containerNameInput}>
                                    <Typography variant="body1" component="h1" className={classes.nameInput}>
                                        Objet *
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <TextField error={Boolean(error.object)} helperText={error.object} variant="outlined"
                                           label="Nécessaire *" className={classes.textField}
                                           inputRef={refObject}/>
                            </Grid>

                            <Grid item xs={4} md={5}>
                                <div className={classes.containerNameInput}>
                                    <Typography variant="body1" component="h1" className={classes.nameInput}>
                                        Message *
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <TextField error={Boolean(error.message)} helperText={error.message} variant="outlined"
                                           label="Nécessaire *" className={classes.textField}
                                           inputRef={refMessage}
                                           multiline/>
                            </Grid>
                            <Grid item xs={4} md={5}>
                                <div className={classes.containerNameInput}>
                                    <Typography variant="body1" component="h1" className={classes.nameInput}>
                                        Vérification *
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_KEY_CAPTCHA} ref={recaptchaRef} style={{
                                    transform: `scale(${scaleRECAPTCHA})`,
                                    transformOrigin: '0px',
                                }}/>
                                <Typography variant="body1" component="h1" className={classes.errorCaptcha}>
                                    {
                                        error.captcha
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={5}>

                            </Grid>
                            <Grid item xs={6} md={5} className={classes.gridButtonSend}>
                                <Button type={"submit"} variant="contained"
                                        className={classes.buttonSend}> Envoyer </Button>
                            </Grid>


                        </Grid>

                    </form>
                </Container>
                <div className={classes.containerInfoContact}>
                    <Grid container className={classes.gridContainerInfoContact}>
                        <Grid item md={6} xs={12} className={classes.gridContainerMap}>
                            <GoogleMapReact
                                bootstrapURLKeys={{key: process.env.REACT_APP_KEY_MAP}}
                                defaultCenter={{
                                    lat: 50.404615,
                                    lng: 3.065864
                                }}

                                defaultZoom={15}
                            >

                            </GoogleMapReact>

                        </Grid>
                        <Grid item md={4} xs={12} className={classes.gridContainerTextInfoContact}>
                            <Typography variant="h5" component="h3" className={classes.infoContactTitle}>
                                Les P'tits Pieds Dans Les Près
                            </Typography>
                            <hr className={classes.hrTitleInfoContact}/>

                            <Typography variant="h6" component="h4" className={classes.infoContactText}>
                                RUE RENE PANHARD
                                <br/>
                                59128
                                <br/>
                                FLERS-EN-ESCREBIEUX
                                <br/>
                                <br/>
                                isabcourmont@gmail.com
                                <br/>
                                03.27.91.20.86
                            </Typography>
                        </Grid>
                        <Grid item md={2} xs={1} className={classes.gridItemInfoContactLast}>

                        </Grid>
                    </Grid>
                </div>

                <Footer/>
            </div>

        </div>
    )
}