import React from "react";
import useStyles from './style';
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

export default function ModalDeleteTheme(props) {

    const classes = useStyles()
    const deletePhotos = () => {
        props.setOpenModalDeleteTheme(false)
        props.setSpinnerValue(1)
        fetch(process.env.REACT_APP_API_URL + "/api/photos/deleteTheme", {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(props.actualDeleteTheme)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return Promise.reject(response);
            }
        })
            .then((response) => {
                props.setSuccess("Thème suprimé")
                props.getTheme()
                props.setSpinnerValue(0)
            })
            .catch(function (error) {
                props.setError("Erreur")
                props.setSpinnerValue(0)
            });
    }


    const body = (
        <div className={classes.paper}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography component="h1" variant="h6" color="inherit" className={classes.title}>
                        Voulez-vous vraiment supprimer ce thème ?
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Button variant="contained" color={"primary"} onClick={deletePhotos}>
                        Valider
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" onClick={() => props.setOpenModalDeleteTheme(false)}>
                        Retour
                    </Button>
                </Grid>

            </Grid>
        </div>
    );

    return (
        <Modal
            open={props.openModalDeleteTheme}
            onClose={() => props.setOpenModalDeleteTheme(false)}
        >
            {body}
        </Modal>
    );

}