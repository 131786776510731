import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    snackbar:{
        width:'75%',
        marginBottom:'15px',

        [theme.breakpoints.down("md")]:{
            width:'100%',
            marginBottom:'0px',

        },
        [theme.breakpoints.down("xs")]:{
            left:'0',
            right:'0',

        },
    },
}));

export default useStyles;