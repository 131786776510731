import React, {useContext} from "react";
import {reducer} from "./reducer";
import {useCookies} from "react-cookie";
const AuthContext = React.createContext();

export function ComponentAuhtContext(props){

    const auth = Auth()

    return (
        <AuthContext.Provider value={auth}> {props.children}</AuthContext.Provider>
    )
}

export  function ContextAuth(props){
    return useContext(AuthContext)
}

function Auth(){
    const [state, dispatch] = React.useReducer(reducer, {
        user: null,
        isAuthenticated: false,
        isLoading: true,
    });

    const [cookies, setCookie, removeCookie] = useCookies(['signature','headerPayload']);

    const signIn = async (submitBody) => {
      
        return await fetch(process.env.REACT_APP_API_URL + "/api/user/signIn", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(submitBody),
        })
            .then((response) => {
                if (response.ok) {

                    return response.json();
                } else {

                    throw new Error('Error in login');
                }
            })
            .then((body) => {

                dispatch({
                    type: "LOGIN",
                    payload: Object.assign(body),
                });

                return "ok";
            })
            .catch(function (error) {
                return error;
            });
    };

    const signOut = async () => {

        return await fetch(
            process.env.REACT_APP_API_URL + '/api/user/signOut',
            {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
                credentials: "include",
            }
        )
            .then((response) => {
                if (response.ok) {
                    dispatch({
                        type: "NOT CONNECTED",
                    });
                    removeCookie("signature")
                    removeCookie("headerPayload")
                    return response.json();

                } else {
                    throw new Error("Deconnection failed");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };




    const checkAuthenticationCookies = () => {
        return getCookie("headerPayload") !== null;
    };
    const handleAuthentication = async () => {

        if (checkAuthenticationCookies()) {

            await fetch(process.env.REACT_APP_API_URL + "/api/user/tryAuth", {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
                credentials: "include",
            })
                .then((response) => {

                    if (response.ok) {

                        return response.json();
                    } else {
                        throw new Error('Not connected');
                    }
                })
                .then((body) => {

                    dispatch({
                        type: "LOGIN",
                        payload: Object.assign(body),
                    });
                })
                .catch(function (error) {
                    console.log(error)
                    dispatch({
                        type: "NOT CONNECTED",
                    });
                });
        } else {
            dispatch({
                type: "NOT CONNECTED",
            });
        }


    };


    React.useEffect(() => {
        handleAuthentication();
    },[]);


    return {
        signIn,
        signOut,
        handleAuthentication,
        state,
    };
}

const getCookie = (name) => {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}