import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    title:{
        marginTop:'50px',
        textAlign:'center',
        fontFamily: theme.font.balooBhai + " !important",
        [theme.breakpoints.down("sm")]:{
            marginTop: theme.size.heightHeaderPhone,
        },
    },

    titlePart:{
        fontFamily: theme.font.balooBhai + " !important",
    },
}));

export default useStyles;