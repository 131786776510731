import React from "react";
import {
    Switch, BrowserRouter
} from "react-router-dom";
import Home from "../Home/home";
import Rate from "../Rate/rate";
import Contact from "../Contact/contact";
import CGU from "../CGU/cgu";
import SignUp from "../SignUp/signUp";
import PrivacyPolicy from "../PrivacyPolicy/privacyPolicy";
import PublicRoute from "./publicRoute";
import Error404 from '../Error404/Error404'
import PrivateRoute from "./privateRoute";
import Photos from "../Photos/photos";
import DashboardAdmin from "../DashboardAdmin/DashboardAdmin";
import ChangePassword from "../ChangePassword/changePassword";


export default function Router() {



    React.useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/api/user/getBasicCookies", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {

                    return response.json();
                } else {

                    throw new Error('Error in login');
                }
            })


    })


    return (




                    <BrowserRouter>
                        <Switch>
                            <PublicRoute component={Home} path="/home" exact/>
                            <PrivateRoute component={Photos} userAccept={["user","admin"]} path="/photos" routeRedirect={"/"} exact/>
                            <PrivateRoute component={DashboardAdmin} userAccept={"admin"} path="/dashboardAdmin" routeRedirect={"/"} exact/>
                            <PrivateRoute component={ChangePassword} userAccept={["user","admin"]} path="/changePassword" routeRedirect={"/"} exact/>
                            <PublicRoute component={Home} path="/" exact/>
                            <PublicRoute component={Rate} path="/rate" exact/>
                            <PublicRoute component={Contact} path="/contact" exact/>
                            <PublicRoute component={CGU} path="/CGU" exact/>
                            <PublicRoute component={SignUp} path="/signUp" exact/>
                            <PublicRoute component={PrivacyPolicy} path="/privacyPolicy" exact/>
                            <PublicRoute component={Error404} path="*"/>

                        </Switch>
                    </BrowserRouter>

    )
}