import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import ReactGA from "react-ga";
import {ContextAuth} from "../../Services/useAuth";
import Spinner from "../Spinner/spinner";
import {useTheme} from "@material-ui/core/styles";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    ReactGA.initialize('UA-174982258-2');
    ReactGA.pageview(window.location.pathname + window.location.search);

    const auth = ContextAuth();
    const theme = useTheme();


    return (
        auth.state.isLoading ? <Spinner loading={true} color={theme.palette.primary.main}/> :
            auth.state.user != null && auth.state.user.isFirstConnection ? <Redirect to={'/changePassword'}/> :

                <Route {...rest} render={props => (
                    <Component {...props} />)}/>

    );
};

export default PublicRoute;