import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: '50px',
        textAlign: 'center',
        fontFamily: theme.font.balsamiq + " !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.size.heightHeaderPhone + 25 + 'px',
        },
    },
    nameInput: {

        paddingRight: '50px',
        fontFamily: theme.font.balsamiq + " !important",
    },
    containerNameInput: {
        [theme.breakpoints.down("sm")]: {
            float: 'none',
        },
        textAlign: 'left',
        float: 'right',
        width: '150px',
    },
    gridContainer: {
        '&>:nth-child(n)': {
            marginTop: '25px',
        },
    },
    textField: {
        width: '100%',
    },
    gridButtonSend: {
        textAlign: 'center',
    },
    buttonSend: {
        width: '100%',
    },
    form: {
        width: '50%',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',

    },
    hrTitle: {
        width: '10%',
        height: '2px',
        backgroundColor: theme.palette.secondary.main,
        border: 'none',
        marginBottom: '25px',
    },
    containerInfoContact: {
        backgroundColor: '#000000B3',
        paddingBottom: '50px',
        marginTop: '75px',

    },
    infoContactTitle: {
        color: '#0000008F',
        fontFamily: theme.font.balsamiq + "!important",
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center',
        },
    },
    infoContactText: {
        color: '#0000008F',
        fontFamily: theme.font.balsamiq + "!important",
        fontWeight: 'normal',
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center',
        },
    },
    hrTitleInfoContact: {
        width: '15%',
        height: '2px',
        backgroundColor: theme.palette.secondary.main,
        border: 'none',
        marginBottom: '25px',
        marginLeft: '0px',
        [theme.breakpoints.down("sm")]: {
            width: '20%',
            margin: 'auto',
        },
    },
    gridContainerInfoContact: {
        margin: 'auto',
        width: '100%',
        paddingLeft: '50px',
        maxWidth: '1500px',
        [theme.breakpoints.down("sm")]: {
            paddingRight: '50px',
            height: 'max-content',
        },
        height: '350px',
        backgroundColor: '#FFFFFF',
    },
    gridItemInfoContactLast: {
        backgroundColor: '#000000B3',
        [theme.breakpoints.down("sm")]: {
            marginTop: '50px',
            backgroundColor: 'white',
        },
    },
    gridContainerTextInfoContact: {
        paddingLeft: '25px',

    },
    gridContainerMap: {
        paddingBottom: '25px',
        [theme.breakpoints.down("sm")]: {
            height: '250px',
        },
    },
    errorCaptcha: {
        fontFamily: 'Roboto',
        color: '#f44336',
        marginLeft:'14px',
        marginRight:'14px',
        fontSize:'0.75rem',
    },
}));

export default useStyles;