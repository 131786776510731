import {makeStyles} from '@material-ui/core/styles';
import firstImage from '../../../Img/imgHome-min.webp'


const useStyles = makeStyles((theme) => ({


    root: {
        position: 'fixed',
        width: '100%',
        top: '0',
        zIndex: '1000',
    },
    phoneContainerTitle: {
        backgroundColor: theme.palette.primary.main,
        padding: '15px 15px 15px 15px',
    },
    phoneTitle: {
        color: 'white',
        fontFamily: theme.font.fugazOne + " !important",
    },
    containerPhoneTitle: {
        flex: '1',
    },
    button: {
        marginRight: '25px',
    },
    link: {
        fontFamily: theme.font.balsamiq + " !important",
        cursor:'pointer',
    },
    containerLink: {
        width: 'max-content !important',
        float: 'left',
        color: 'black',
    },
    separator: {
        marginLeft: '5px !important',
        marginRight: '5px  !important',
    },
    containerLinkIcons: {
        display: 'flex',
        alignItems: 'center',
        height:'100%',
        color:'white',
    },
    icons:{
        cursor:'pointer',
    },
    imgHome:{
        width:'250px',
        overflow: 'hidden',
        backgroundImage: `url(${firstImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
}));

export default useStyles;