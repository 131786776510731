import {makeStyles} from '@material-ui/core/styles';
import firstDivider from '../../Img/firstDivider-min.webp'
import firstImage from '../../Img/imgHome-min.webp'


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    containerImgLogo: {
        width: '100%',
        height: theme.size.headerDesktop,
        position: 'absolute',
        top: '0',
        zIndex: '1',
        overflow: 'hidden',
        backgroundImage: `url(${firstImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    containerImgLogoPhone: {

        top: 'unset',
        position: 'unset',
        width: '95%',
        height: '150px',
        boxShadow: '0 0 5px black',
        margin: 'auto',
        marginTop: theme.size.heightHeaderPhone + 20 + "px",
        overflow: 'hidden',
        backgroundImage: `url(${firstImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

    },
    titleImplication: {
        fontWeight: 'bold !important',
        textAlign: 'center',
        color: 'white',
        fontFamily: theme.font.annieUseYourTelescope + "!important",
    },
    implication: {
        overflow: 'hidden',
        marginTop: 'calc(' + theme.size.headerDesktop + ' - ' + 150 + 'px)',
        marginBottom: '25px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '15px',
        },
    },
    paperImplication: {
        backgroundColor: '#fc4935 !important',
        padding: '25px',
    },
    hrTitle: {
        width: '15%',
        height: '2px',
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        marginBottom: '25px',
    },
    iconImplication: {
        fill: 'white !important',
        fontSize: '2.3em !important',
    },
    containerOfOneImplication: {
        marginTop: '25px',
        textAlign: 'center',
    },
    textOfOneImplication: {

        color: 'white',
        fontFamily: theme.font.annieUseYourTelescope + "!important",
    },
    containerWave: {
        top: '1px',
        position: 'relative',
        height: '50px',
    },
    wave: {
        fill: '#FF894A',
    },
    containerDevelopment: {
        backgroundColor: '#FF894A',
        paddingBottom: '45px',
        overflow: 'hidden',
    },
    titleOurCrib: {
        fontWeight: 'bold !important',
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontFamily: theme.font.annieUseYourTelescope + "!important",
    },

    containerFirstImgCrib: {
        marginTop: '30px !important',
        width: '100%',
        height: '700px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    containerSecondImgCrib: {
        height: '450px',
        textAlign: 'center',
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    imgCribFirst: {
        height: '70%',
        // boxShadow: '-6px 6px 7px 3px #111',
    },
    imgCribSecond: {
        height: '100%',
        //boxShadow: '6px 6px 7px 3px #ccc',
    },
    underTitleCrib: {
        fontWeight: 'bold !important',
        textAlign: "center",
        color: theme.palette.primary.main,
        fontFamily: theme.font.annieUseYourTelescope + "!important",
    },
    textTitleCrib: {
        textAlign: 'justify',
        color: 'white',
        fontFamily: theme.font.annieUseYourTelescope + "!important",
    },
    containerTextTitleCrib: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '30px !important',
            marginBottom: '0px !important',
        },
        marginBottom: '50px !important',
        width: '550px',
    },
    firstDividerText: {
        fontFamily: theme.font.annieUseYourTelescope + "!important",
        textAlign: 'center',
        color: 'white',
    },
    firstDivider: {
        padding: '5px 25px 5px 25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '150px',
        backgroundImage: `url(${firstDivider})`,
    },
    containerIconImplication: {
        textAlign: 'center',
    },
    contactUsFirstDividerText: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    titleFindUs: {
        fontWeight: 'bold !important',
        textAlign: 'center',
        color: theme.palette.third.main,
        fontFamily: theme.font.annieUseYourTelescope + "!important",
    },
    titleFindUsCard: {
        fontWeight: 'bold !important',
        color: '#0000008F',
        fontFamily: theme.font.annieUseYourTelescope + "!important",
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center',
        },
    },
    hrTitleGrey: {
        width: '15%',
        height: '2px',
        marginLeft: '0px !important',
        backgroundColor: theme.palette.secondary.main,
        border: 'none',
        marginBottom: '25px',
        [theme.breakpoints.down("sm")]: {
            marginLeft: 'auto !important',
        },
    },
    hrUnderTitleOurCrib: {
        width: '15%',
        height: '2px',
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        marginBottom: '25px',
    },
    paperFindUs: {
        width: '100%',
        marginBottom: '25px',
    },
    gridFindUs: {
        marginTop: '25px',
    },
    textFindUsCard: {
        fontWeight: 'bold !important',
        color: '#0000008F',
        fontFamily: theme.font.annieUseYourTelescope + "!important",
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center',
        },
    },
    putInFrontText: {
        color: '#8C8C8C',

    },
    imgFindUs: {
        height: "300px",
    },
    containerImgFindUs: {
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            display: 'none',
        },
    },
    expandMore: {
        fontSize: '3em !important',
        color: 'white',
    },
    containerExpandMore: {
        position: 'absolute',
        zIndex: '2',
        top: 'calc(' + theme.size.headerDesktop + " - 10vh)",
        [theme.breakpoints.down("md")]: {
            top: 'calc(' + theme.size.headerMiniDesktop + " - 10vh)",
        },
        left: '50%',
        animation: "1.5s $slidein infinite",
    },
    marginTop: {
        [theme.breakpoints.down("sm")]: {
            marginTop: '5px !important',
        },

    },
    gridDataFindUs: {
        padding: '25px',
    },
    marginLeft: {
        marginLeft: '10px',
    },

    containerTextTitleCribSecond: {
        marginTop: '13px !important',
    },
    hrTitleColorThird: {
        width: '15%',
        height: '2px',
        backgroundColor: theme.palette.third.main,
        border: 'none',
        marginBottom: '25px',
    },
    paperContainerFindUs: {
        backgroundColor: theme.palette.primary.main + ' !important',
    },
    hrUnderImplication: {
        width: '15%',
        height: '2px',
        backgroundColor: 'white',
        border: 'none',
        marginBottom: '25px',
    },


    '@keyframes slidein': {
        from: {
            transform: 'translate(-50%,-50%)'
        },
        to: {
            //transform: 'translate(-50% ,-50%)',
            transform: 'translate(calc(-50% ),calc(-50% + 20px))'
            //transform: 'translate(0px ,)',

        }
    }
}));

export default useStyles;