import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: '5px',
        textAlign: 'center',
        fontFamily: theme.font.balsamiq + " !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: '10px',
        },

    },
    gridButtonSend:{
        marginTop:'25px',
        textAlign:'center',
    },
    img:{
      width:'100%',
    },
    accordion: {
        width: '80%',
        marginTop:'25px',
    },
    heading: {
        wordBreak:'break-word',
        flex: '1',
        textAlign: 'center',
    },
    deleteIcon:{
        color:'red'
    },
}));

export default useStyles;