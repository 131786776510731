import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: '5px',
        textAlign: 'center',
        fontFamily: theme.font.balsamiq + " !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: '10px',
        },

    },
    root: {
        marginBottom: '15px',
    },
    containerAllUsers: {
        padding: '15px 15px 15px 15px',
    },
    paperUser: {
        marginTop: '15px',
        padding: '15px 15px 15px 15px',
    },
    userFirstName:{
        fontFamily: theme.font.balooBhai + " !important",
    },
    userLastName:{
        fontFamily: theme.font.balooBhai + " !important",
    },
    buttonDelete:{
        backgroundColor: 'rgb(220, 0, 78)',
        color:'white',
        margin:"5px",
        fontFamily: theme.font.balooBhai + " !important",
    },
    buttonResetPassword:{
        backgroundColor: 'rgb(220, 0, 78)',
        color:'white',
        margin:"5px",
        fontFamily: theme.font.balooBhai + " !important",
    },
    allButton:{
        display:'flex',
        justifyContent:'center',
    },
    paperFilter:{
        margin:'15px 15px 15px 15px',
        padding: '15px 15px 15px 15px',
    },
    buttonFilter:{
        fontFamily: theme.font.balsamiq + " !important",
        color:'white',
    },
    buttonReset:{
        fontFamily: theme.font.balsamiq + " !important",
    },
    gridContainerField:{
      marginBottom:'25px',
    },
}));

export default useStyles;