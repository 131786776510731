import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '2px',
        backgroundColor: '#A07145BF',
        textAlign:'center',
        paddingBottom: '25px',
        color:'white',
        width:'100%',
    },
    hr:{
      width:'50%',
    },
    button:{
      cursor:'pointer'
    },

}));

export default useStyles;