import React from "react";
import useStyles from "./style";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useHistory, useLocation} from "react-router";
import clsx from "clsx";

export default function NavDesktop(props) {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    let style;
    if (props.auth.state.isAuthenticated) {
        style = props.auth.state.user.type === "user" ? {
                width: '90%',
                maxWidth: '1300px',
                minWidth: '940px',
            } :
            {
                width: '90%',
                maxWidth: '1300px',
                minWidth: '940px',
            };
    } else {
        style = {
            width: '70%',
            maxWidth: '1100px',
        }
    }


    return (
        <div className={classes.root}>
            <div style={style} className={classes.container}>
                <Grid container justify="space-around" alignItems="center">
                    <Grid item xs={2}
                          className={clsx((location.pathname !== '/home' && location.pathname !== '/') && classes.gridContainerOfLinkAnimation, classes.gridContainerOfLink)}>
                        <Typography variant="h6" component="h3"
                                    className={clsx((location.pathname === '/home' || location.pathname === '/') ? classes.underline : classes.link)}
                                    onClick={() => history.push('/home')}>
                            Accueil
                        </Typography>
                    </Grid>
                    <Grid item xs={2}
                          className={clsx(location.pathname !== '/contact' && classes.gridContainerOfLinkAnimation, classes.gridContainerOfLink)}>
                        <Typography variant="h6" component="h3"
                                    className={clsx(location.pathname === '/contact' ? classes.underline : classes.link)}
                                    onClick={() => history.push('/contact')}>
                            Contact
                        </Typography>
                    </Grid>
                    <Grid item xs={2}
                          className={clsx(location.pathname !== '/rate' && classes.gridContainerOfLinkAnimation, classes.gridContainerOfLink)}>
                        <Typography variant="h6" component="h3"
                                    className={clsx(location.pathname === '/rate' ? classes.underline : classes.link)}
                                    onClick={() => history.push('/rate')}>
                            Tarif
                        </Typography>
                    </Grid>
                    <Grid item xs={2}
                          className={clsx(location.pathname !== '/signUp' && classes.gridContainerOfLinkAnimation, classes.gridContainerOfLink)}>
                        <Typography variant="h6" component="h3"
                                    className={clsx(location.pathname === '/signUp' ? classes.underline : classes.link)}
                                    onClick={() => history.push('/signUp')}>
                            Pré-Inscription
                        </Typography>
                    </Grid>
                    {
                        props.auth.state.isAuthenticated &&

                        <Grid item xs={2}
                              className={clsx(location.pathname !== '/photos' && classes.gridContainerOfLinkAnimation, classes.gridContainerOfLink)}>
                            <Typography variant="h6" component="h3"
                                        className={clsx(location.pathname === '/photos' ? classes.underline : classes.link)}
                                        onClick={() => history.push('/photos')}>
                                Voir les Photos
                            </Typography>
                        </Grid>
                    }
                    {
                        props.auth.state.user !== null && props.auth.state.user.type === "admin" &&

                        <Grid item xs={2}
                              className={clsx(location.pathname !== '/dashboardAdmin' && classes.gridContainerOfLinkAnimation, classes.gridContainerOfLink)}>
                            <Typography variant="h6" component="h3"
                                        className={clsx(location.pathname === '/dashboardAdmin' ? classes.underline : classes.link)}
                                        onClick={() => history.push('/dashboardAdmin')}>
                                Admin
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        </div>
    )
}