import React from "react";
import {useTheme} from "@material-ui/core";
import HeaderDesktop from "./HeaderDesktop/headerDesktop";
import HeaderPhone from "./HeaderPhone/headerPhone";

export default function Header(props) {


    const theme = useTheme();
   
    return (

        props.width >= theme.breakpoints.values.md ?
            <HeaderDesktop auth={props.auth}/>

            :

            <HeaderPhone auth={props.auth}/>


    )
}