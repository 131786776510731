import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({

    form: {
        width: '100%',
    },
    titleModify: {
        fontFamily: theme.font.balooBhai + " !important",
    },
    textFieldModify: {
        width: '250px',
        paddingTop: '5px',
        paddingLeft: '15px',
    },
    containerComponentModified: {
        width: 'max-content',
        display: "inline-block",
        marginRight: "25px",
    },
    section: {
        marginTop: '15px',
    },
    radioGroup: {
        width: '100%',
        flexDirection: 'row !important',
        [theme.breakpoints.down("sm")]:{
            flexDirection: 'column !important',
        },
        justifyContent: 'space-around',
    },
    titlePart:{
        fontFamily: theme.font.balsamiq + " !important",
        marginBottom: '15px',
        textAlign: 'center',
    },
    titleTable:{
        marginTop:'25px',
        fontFamily: theme.font.balsamiq + " !important",
    },
    infoTimeReceiveChildren:{
        fontFamily: theme.font.balooBhai + " !important",
    },
    certificate:{
        fontFamily: theme.font.balooBhai + " !important",
    },
    gridButtonSend: {
        textAlign: 'center',
    },
    buttonSend:{
        width:'100%',
    },
    table:{
        width:'auto',
    },
    inlineTypo:{
        width:'max-content',
        display:'inline-block',

    },
    radioGroupInline:{
        marginLeft:'20px',
        width:'max-content',
        display:'inline-block',
        flexDirection: 'row !important',
        [theme.breakpoints.down("sm")]:{
            flexDirection: 'column !important',
        },
    },
    infoExplicationPlanning:{
        marginTop:'15px',
    },
    textFieldInfoExplicationPlanning:{
        width:'100%',
    },
}));

export default useStyles;