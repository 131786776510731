import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: '50px',
        textAlign: 'center',
        fontFamily: theme.font.balsamiq + " !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.size.heightHeaderPhone + 25 + 'px',
        },
    },
    hrTitle: {
        width: '10%',
        height: '2px',
        backgroundColor: theme.palette.secondary.main,
        border: 'none',
        marginBottom: '25px',
    },
    underTitle:{
        textAlign: 'center',
        fontFamily: theme.font.balsamiq + " !important",
    },
    formControl:{
      width:'80%',
      maxWidth:'500px',
    },
    containerForm:{
        textAlign:'center',
        marginTop:'25px',
        marginBottom: '25px',
    },
}));

export default useStyles;