import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import useStyles from "./style";


export default function CreateCategory(props) {

    const classes = useStyles();

    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");


    const [name, setName] = React.useState("");


    const handleSubmit = async (e) => {
        if (name === "") {
            setError('Veuillez préciser le nom du thème')
        } else {
            props.setSpinnerValue(1);
            let submitBody = {
                name: name,
            };
            fetch(process.env.REACT_APP_API_URL + "/api/theme/createTheme", {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(submitBody)
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return Promise.reject(response);
                    }
                })
                .then((response) => {
                    setSuccess("Le thème a bien été crée");
                    setName('')
                    props.setSpinnerValue(0)
                })
                .catch(function (error) {
                    props.setSpinnerValue(0)
                    error.json().then((body) => {
                        if (body.message === "Email already use") {
                            setError("Email déjà utilisée")
                        } else {
                            setError("Erreur dans l'ajout de l'utilisateur")
                        }


                    });

                });
        }

    };


    return (
        <div className={classes.root}>
            <Typography variant="h5" component="h1" className={classes.title}>
                Créer un thème
            </Typography>
            <Grid item xs={12} className={classes.gridButtonSend}>
                {
                    error && <Alert severity="error">{error}</Alert>
                }
                {
                    success && <Alert severity="success">{success}</Alert>
                }
            </Grid>
            <Grid container justify="flex-start" alignItems="center" className={classes.gridContainer} spacing={1}>
                <Grid item xs={12} sm={2}>
                    <div className={classes.containerNameInput}>
                        <Typography variant="body1" component="h1" className={classes.nameInput}>
                            Nom :
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField variant="outlined" label="Nécessaire *" value={name} className={classes.textField}
                               onChange={(e) => setName(e.target.value)}/>
                </Grid>

                <Grid item xs={12} className={classes.gridButtonSend}>
                    <Button onClick={handleSubmit} type={"submit"} variant="contained"
                            className={classes.buttonSend}> Envoyer </Button>
                </Grid>


            </Grid>
        </div>
    )
}