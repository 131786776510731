import React from "react";
import useStyles from "./style";
import Header from "../Header/header";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AcceptCGU from "../AcceptCGU/acceptCGU";
import Footer from "../Footer/footer";
import {ContextAuth} from "../../Services/useAuth";

export default function PrivacyPolicy() {

    const classes = useStyles();
    const auth = ContextAuth();
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    React.useEffect(() => {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                });
            }
            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        }
    ,[]);



    return (
        <div>
            {
                auth.state.isLoading ? "" :
                    <div>
                        <AcceptCGU/>
                        <Header width={dimensions.width}  auth={auth}/>
                        <Grid container justify={"center"}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h3" className={classes.title}>
                                    Politique de confidentialité
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    Données personnelles
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Sur le site web www.les-ptits-pieds-dans-mes-pres.fr, il y a 2 types de données
                                    susceptibles d’être recueillies :
                                    <br/>
                                    • Les données transmises directement
                                    <br/>
                                    Ces données sont celles que vous nous transmettez directement, via un formulaire de
                                    contact ou bien par contact direct par email. Sont obligatoires dans le formulaire
                                    de contact le champs « prénom et nom », « entreprise ou organisation » et « email ».
                                    <br/>
                                    • Les données collectées automatiquement
                                    <br/>
                                    Lors de vos visites, une fois votre consentement donné, nous pouvons recueillir des
                                    informations de type « web analytics » relatives à votre navigation, la durée de
                                    votre consultation, votre adresse IP, votre type et version de navigateur. La
                                    technologie utilisée est le cookie.

                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    Utilisation des données
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Sur le site web www.les-ptits-pieds-dans-mes-pres.fr, il y a 2 types de données
                                    susceptibles d’être recueillies :
                                    <br/>
                                    • Les données transmises directement
                                    <br/>
                                    Ces données sont celles que vous nous transmettez directement, via un formulaire de
                                    contact ou bien par contact direct par email. Sont obligatoires dans le formulaire
                                    de contact le champs « prénom et nom » et « email ».
                                    <br/>
                                    • Les données collectées automatiquement
                                    <br/>
                                    Lors de vos visites, une fois votre consentement donné, nous pouvons recueillir des
                                    informations de type « web analytics » relatives à votre navigation, la durée de
                                    votre consultation, votre adresse IP, votre type et version de navigateur. La
                                    technologie utilisée est le cookie.

                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    Base légale
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Les données personnelles ne sont collectées qu’après consentement obligatoire de
                                    l’utilisateur. Ce consentement est valable tant que vous continuez la navigation sur
                                    le site, libre. </Typography>
                                <br/>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    Cookies
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Voici la liste des cookies utilisées et leur objectif :
                                    <br/>
                                    • Cookies Google Analytics <a
                                    href={"https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"}> (liste
                                    exhaustive)</a> : Web analytics
                                    <br/>
                                    • Cookies Remember Me : Utilisé pour retenir les informations de navigation de l'utilisateur ( CGU acceptée )
                                    <br/>
                                    • Cookies Authentification : Utilisé pour permettre la connexion au site
                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    Vos droits concernant les données personnelles
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Vous avez le droit de consultation, demande de modification ou d’effacement sur
                                    l’ensemble de vos données personnelles. Vous pouvez également retirer votre
                                    consentement au traitement de vos données.
                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <Typography variant="h6" component="h4" className={classes.titlePart}>
                                    Contact
                                </Typography>
                            </Grid>
                            <Grid item xs={11} md={7}>
                                <br/>
                                <Typography variant="body2" className={classes.text}>
                                    Vous avez à votre disposition plusieurs moyens de nous contacter :
                                    <br/>
                                    . Formulaire de contact présent sur le site
                                    <br/>
                                    . par mail : weirding.code@gmail.com
                                </Typography>
                                <br/>
                            </Grid>
                        </Grid>
                        <Footer/>
                    </div>
            }
        </div>
    )
}