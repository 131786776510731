import React from "react";
import useStyles from "./style";
import Header from "../Header/header";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Footer from "../Footer/footer";
import AcceptCGU from "../AcceptCGU/acceptCGU";
import {ContextAuth} from "../../Services/useAuth";

export default function Rate() {

    const classes = useStyles();
    const auth = ContextAuth();

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    React.useEffect(() => {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth,
                });
            }

            window.addEventListener('resize', handleResize);
            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        }
    );


    return (
        <div>
            {
                auth.state.isLoading ? "" :
                    <div>
                        <AcceptCGU/>
                        <Header width={dimensions.width}  auth={auth}/>
                        <Typography variant="h4" component="h1" className={classes.title}>
                            Nos Tarifs
                        </Typography>
                        <hr className={classes.hrTitle}/>


                        <div className={classes.rootTable}>
                            <TableContainer component={Paper} className={classes.table}>
                                <Table aria-label="spanning table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={1} rowSpan={2}>
                                                <Typography variant="body1" component="h1"
                                                            className={classes.titleTable}>
                                                    Temps d'accueil
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" colSpan={3} rowSpan={1}
                                                       className={classes.titleTable}>
                                                <Typography variant="body1" component="h1"
                                                            className={classes.titleTable}>
                                                    Tarifs horaire en fonction des revenus et du nombre d'heures
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>

                                            <TableCell rowSpan={1} align="right">Revenus inférieurs à 20 000
                                                €</TableCell>
                                            <TableCell rowSpan={1} align="center">Revenus compris entre 20 000 € et 35
                                                000
                                                €</TableCell>
                                            <TableCell rowSpan={1} align="center">Revenus supérieurs à 35 000
                                                €</TableCell>
                                        </TableRow>

                                        <TableRow className={classes.greyBackground}>
                                            <TableCell rowSpan={1}>De 25 à 30 heures / semaine  </TableCell>
                                            <TableCell rowSpan={1} align="center">8,20 €</TableCell>
                                            <TableCell rowSpan={1} align="center">8,70 €</TableCell>
                                            <TableCell rowSpan={1} align="center">9,20 €</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell rowSpan={1}>De 30 heures à 45 heures / semaine </TableCell>
                                            <TableCell rowSpan={1} align="center">8 €</TableCell>
                                            <TableCell rowSpan={1} align="center">8,50 €</TableCell>
                                            <TableCell rowSpan={1} align="center">9 €</TableCell>
                                        </TableRow>

                                        <TableRow className={classes.greyBackground}>
                                            <TableCell rowSpan={1}>Supérieur à 45 heures / semaine </TableCell>
                                            <TableCell rowSpan={1} align="center">7,80 €</TableCell>
                                            <TableCell rowSpan={1} align="center">8,20 €</TableCell>
                                            <TableCell rowSpan={1} align="center">8,80 €</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                        <Footer/>
                    </div>}
        </div>
    )
}