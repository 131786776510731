import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    titleTheme: {
        textAlign: 'center',
        backgroundColor: '#B0B0B0',
        paddingTop: '7px',
        paddingBottom: '7px',
        wordWrap: 'break-word',
        fontFamily: theme.font.balsamiq + " !important",
    },
    containerDiv: {
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        height:'100%',
    },
    carousel: {
        width:'auto',
        marginBottom: '85px',
    },
    title: {
        marginBottom: '50px',
        marginTop: '50px',
        textAlign: 'center',
        fontFamily: theme.font.balsamiq + " !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.size.heightHeaderPhone,
        },
    },
    place: {
        border: '2px solid black',
        borderRadius: '3px',
    },
    container: {
        marginTop: '25px',


    },
    download:{
        '&:hover:first-child ':{
            opacity:'0.4',
        },
        '&:hover :nth-child(2)':{
            opacity:'1',
        },
        padding: '5px',
        display: 'flex',
        height:'max-content',
        alignContent: 'center',
    },
    img: {
        width: '100%',
        alignSelf:'center',

    },

    allImgContainer: {
        marginTop: '2px',
    },
    pagination: {
        display: 'flex',
        marginTop: '5px',
        marginBottom: '5px',
        justifyContent: 'center',
    },
    gridImg: {
        padding: '2px',
    },
    responsiveGridRow: {
        display: 'flex',
        padding: ' 0 4px',
    },
    responsiveGridColumn: {
        flex: '50%',
        maxWidth: '50%',
        padding: '0 4px',
    },
    middle :{
        transition: ".5s ease",
        opacity: "0",
        position: "absolute",
        top: "50%",
        left:" 50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        backgroundColor: "#FFFFFF",
        padding: "12px",
    },

}));

export default useStyles;