import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: '5px',
        textAlign: 'center',
        fontFamily: theme.font.balsamiq + " !important",
        [theme.breakpoints.down("sm")]: {
            marginTop: '10px',
        },
    },

    buttonSend:{
        width:'50%',
    },
    gridButtonSend:{
        marginTop:'25px',
        textAlign:'center',
    },
    textField:{
        width:'80%',
    },
    nameInput:{
        display:'inline',
    },
    gridContainer:{
        paddingRight:'25px',
        paddingLeft:'25px',
        marginTop: '25px',
        [theme.breakpoints.down("xs")]: {
            marginTop: '0px',
        },
    },
    root: {
        marginBottom: '15px',
    },
}));

export default useStyles;