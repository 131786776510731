import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import NavDesktop from "../../NavDesktop/navDesktop";
import useStyles from "./style";
import PopperConnection from "../../PopperConnection/popperConnection";
import {useHistory} from "react-router";


export default function HeaderDesktop(props) {
    const classes = useStyles();
    const [anchorElConnection, setAnchorElConnection] = React.useState(null);
    const [openConnection, setOpenConnection] = React.useState(false);
    const history = useHistory()

    return (


        <div className={classes.container}>
            <PopperConnection anchorEl={anchorElConnection} open={openConnection}
                              setOpen={setOpenConnection}/>
            <div className={classes.firstHeader}>

                <Grid container className={classes.containerLink}>
                    <Grid item>
                        {
                            props.auth.state.isAuthenticated ?
                                <Typography variant="h6" component="h3" className={classes.link}
                                            onClick={(e) => {
                                                props.auth.signOut()
                                            }}>

                                    Se déconnecter
                                </Typography> :
                                <Typography variant="h6" component="h3" className={classes.link}
                                            onClick={(e) => {
                                                setAnchorElConnection(e.currentTarget)
                                                setOpenConnection(!openConnection)
                                            }}>

                                    Se connecter
                                </Typography>
                        }

                    </Grid>
                    <Grid item>
                        <Typography variant="h6" component="h3" className={classes.separator}>
                            |
                        </Typography>
                    </Grid>
                    <Grid item >
                        <a href={"https://www.facebook.com/Les-Ptits-Pieds-dans-les-Pr%C3%A9s-253155721872463"} className={classes.containerLinkIcons}>
                            <FacebookIcon className={classes.icons} />
                        </a>

                    </Grid>
                </Grid>
                <Typography variant="h4" component="h1" className={classes.title}>
                    Les P'tits Pieds Dans Les Prés
                </Typography>
                <Typography variant="h5" component="h2" className={classes.underTitle}>
                    Votre nouvelle crèche à FLERS-EN-ESCREBIEUX
                </Typography>
            </div>
            <NavDesktop auth={props.auth}/>
        </div>

    )

}